import { env } from "../utils/const";
import { config, keys } from "./config";

declare global {
	interface Window {
		$crisp: unknown[];
		CRISP_WEBSITE_ID: string;
	}
}

const initCrisp = (): void => {
	if (typeof window === "undefined") return;
	if (config.env === env.DEV) return;

	window.$crisp = [];
	window.$crisp.push(["safe", true]);
	window.CRISP_WEBSITE_ID = keys.CRISP as string;

	const script = document.createElement("script");
	script.src = "https://client.crisp.chat/l.js";
	script.async = true;

	document.head.appendChild(script);
};

export { initCrisp };
