import axios from "axios";

import { discordProxy } from "../config/config";

const sendMessage = (data: unknown) => {
	const updatedOptions = {
		headers: { "content-type": "application/json" }
	};

	return axios.post(discordProxy.contactUs as string, data, updatedOptions);
};

const discordAPI = {
	sendMessage
};

export default discordAPI;
