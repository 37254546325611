const config = {
	env: process.env.NEXT_PUBLIC_ENV,
	proxy: {
		basicToken: process.env.NEXT_PUBLIC_PROXY_TOKEN,
		url: process.env.NEXT_PUBLIC_PROXY_URL
	},
	sentryKey: process.env.NEXT_PUBLIC_SENTRY_KEY,
	version: "1.0.0"
};

const getBaseURL = (landing?: boolean): string => {
	return landing
		? (process.env.NEXT_PUBLIC_LANDING_URL as string)
		: (process.env.NEXT_PUBLIC_APP_URL as string);
};

const keys = {
	CRISP: process.env.NEXT_PUBLIC_CRISP_KEY,
	GOOGLE_TAG_MANAGER: process.env.NEXT_PUBLIC_GTM
};

const discordProxy = {
	contactUs: process.env.NEXT_PUBLIC_CONTACT_US_URL
};

const getCrossDomain = (): string =>
	process.env.NEXT_PUBLIC_CROSS_DOMAIN as string;

const pipedrive = {
	baseUrl: process.env.NEXT_PUBLIC_PIPEDRIVE_URL,
	token: process.env.NEXT_PUBLIC_PIPEDRIVE_TOKEN,
	sourceFieldKey: process.env.NEXT_PUBLIC_SOURCE_FIELD_KEY
};

export { config, discordProxy, getBaseURL, getCrossDomain, keys, pipedrive };
