import { FC, MouseEvent, useContext } from "react";
import { useCookies } from "react-cookie";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";
import Link from "next/link";

import { Button } from "ui-kit";

import { config, getCrossDomain } from "../../config/config";
import { updateGTM } from "../../config/ga";

import ModalContext from "../../context/modal";

import { Cookies } from "../../types/types";
import { cookies, env, pages } from "../../utils/const";

const cookieName =
	config.env === env.PROD
		? cookies.CONSENT
		: `${cookies.CONSENT}_${config.env}`;

interface IProps {
	t: TFunction;
}

const CookiesBanner: FC<IProps> = ({ t }): JSX.Element | null => {
	const [cookie, setCookie] = useCookies([cookieName]);

	const { cookieManageActive, toggleCookieManageModal } =
		useContext(ModalContext);

	const accept = (event: MouseEvent): void => {
		event.preventDefault();

		const cookiesAllAccepted: Cookies = {
			security_storage: true,
			ad_personalization: true,
			ad_storage: true,
			ad_user_data: true,
			analytics_storage: true,
			functionality_storage: true,
			personalization_storage: true
		};

		updateGTM(cookiesAllAccepted);

		setCookie(
			cookieName,
			{ ...cookiesAllAccepted },
			{
				path: "/",
				domain: getCrossDomain()
			}
		);
	};

	if (cookie[cookieName] || cookieManageActive) return null;

	return (
		<div className="c-wrapper">
			<div className="c-banner">
				<div className="c-banner__title">{t("common:COOKIES.TITLE")}</div>

				<div className="c-banner__note">
					{t("common:COOKIES.NOTE_START")}

					<span>
						{" "}
						<Link href={pages.COOKIE_POLICY}>
							{t("common:COOKIES.NOTE_LINK")}
						</Link>{" "}
					</span>

					{t("common:COOKIES.NOTE_END")}
				</div>

				<Button
					className="button--full-width c-banner__accept-button"
					size="sm"
					onClick={accept}
				>
					{t("common:COOKIES.ACCEPT")}
				</Button>

				<Button
					className="button--full-width"
					brand="secondary"
					size="sm"
					onClick={toggleCookieManageModal}
				>
					{t("common:COOKIES.MANAGE")}
				</Button>
			</div>
		</div>
	);
};

export default CookiesBanner;
