/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";
import { ArticlePost } from "../../types/blog/blog";

const BlogContext = createContext({
	blogArticles: [] as ArticlePost[],
	blogSearch: "",
	loading: false,
	setBlogArticles: (articles: ArticlePost[]) => {},
	setBlogSearch: (query: string) => {},
	setLoading: (loading: boolean) => {}
});

export default BlogContext;
