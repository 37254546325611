import { FC, useState } from "react";

import { ArticlePost } from "../../types/blog/blog";

import BlogContext from "./index";

interface IProps {
	children: JSX.Element;
}

const BlogProvider: FC<IProps> = ({ children }): JSX.Element => {
	const [blogSearch, setBlogSearch] = useState<string>("");
	const [blogArticles, setBlogArticles] = useState<ArticlePost[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<BlogContext.Provider
			value={{
				blogArticles,
				blogSearch,
				loading,
				setLoading,
				setBlogArticles,
				setBlogSearch
			}}
		>
			{children}
		</BlogContext.Provider>
	);
};

export default BlogProvider;
