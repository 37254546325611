import { useEffect } from "react";
import { keyCodes } from "../utils/const";

const useKeyboardFocus = (): void => {
	useEffect(() => {
		const onTab = (event: KeyboardEvent) => {
			if (event.keyCode === keyCodes.TAB) {
				document.documentElement.setAttribute("data-tabbing", "true");

				window.removeEventListener("keydown", onTab);
				window.addEventListener("mousedown", onMouse);
			}
		};

		const onMouse = () => {
			document.documentElement.removeAttribute("data-tabbing");

			window.removeEventListener("mousedown", onMouse);
			window.addEventListener("keydown", onTab);
		};

		window.addEventListener("keydown", onTab);

		return () => {
			document.documentElement.removeAttribute("data-tabbing");
			window.removeEventListener("mousedown", onMouse);
			window.removeEventListener("keydown", onTab);
		};
	}, []);
};

export default useKeyboardFocus;
