import i18n from "../config/i18n";

import media from "../public/assets/media";

import { getBaseURL } from "../config/config";

import { pages } from "./const";

const og = {
	blog: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.BLOG.DESCRIPTION"),
		directlyAccessible: true,
		title: i18n.t("common:META.BLOG.TITLE"),
		url: `${getBaseURL(true)}${pages.BLOG}`
	},
	cookiePolicy: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.COOKIE_POLICY.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.COOKIE_POLICY.TITLE"),
		url: `${getBaseURL(true)}${pages.COOKIE_POLICY}`
	},

	commerce: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.COMMERCE.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.COMMERCE.TITLE"),
		url: `${getBaseURL(true)}${pages.COMMERCE}`
	},

	communications: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.COMMUNICATIONS.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.COMMUNICATIONS.TITLE"),
		url: `${getBaseURL(true)}${pages.COMMUNICATIONS}`
	},

	eLearning: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.E_LEARNING.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.eLearning,
		title: i18n.t("common:META.E_LEARNING.TITLE"),
		url: `${getBaseURL(true)}${pages.E_LEARNING}`
	},

	eventPlatforms: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.EVENT_PLATFORMS.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.EVENT_PLATFORMS.TITLE"),
		url: `${getBaseURL(true)}${pages.EVENT_PLATFORMS}`
	},

	features: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.FEATURES.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.features,
		title: i18n.t("common:META.FEATURES.TITLE"),
		url: `${getBaseURL(true)}${pages.FEATURES_WEB_APPS}`
	},

	home: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.HOME.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.HOME.TITLE"),
		url: `${getBaseURL(true)}${pages.HOME}`
	},

	load: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.LOAD.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.load,
		title: i18n.t("common:META.LOAD.TITLE"),
		url: `${getBaseURL(true)}${pages.FEATURES_LOAD}`
	},

	loadTestExecution: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.LOAD_TEST_EXECUTION.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.load,
		title: i18n.t("common:META.LOAD_TEST_EXECUTION.TITLE"),
		url: `${getBaseURL(true)}${pages.SERVICES_LOAD_TESTING}`
	},

	monitoring: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.MONITORING.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.features,
		title: i18n.t("common:META.MONITORING.TITLE"),
		url: `${getBaseURL(true)}${pages.FEATURES_MONITORING}`
	},

	notFound: {
		directlyAccessible: false,
		title: i18n.t("common:META.NOT_FOUND.TITLE"),
		url: `${getBaseURL(true)}${pages.PAGE_NOT_FOUND}`
	},

	partner: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.PARTNER.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.PARTNER.TITLE"),
		url: `${getBaseURL(true)}${pages.PARTNER}`
	},

	performance: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.PERFORMANCE.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.features,
		title: i18n.t("common:META.PERFORMANCE.TITLE"),
		url: `${getBaseURL(true)}${pages.FEATURES_PERFORMANCE}`
	},

	performanceMonitoring: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.PERFORMANCE_MONITORING.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.features,
		title: i18n.t("common:META.PERFORMANCE_MONITORING.TITLE"),
		url: `${getBaseURL(true)}${pages.PERFORMANCE_MONITORING}`
	},

	pricing: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.PRICING.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.PRICING.TITLE"),
		url: `${getBaseURL(true)}${pages.PRICING}`
	},

	privacyPolicy: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.PRIVACY_POLICY.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.PRIVACY_POLICY.TITLE"),
		url: `${getBaseURL(true)}${pages.PRIVACY_POLICY}`
	},

	remarketingLoad: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.REMARKETING_LOAD.DESCRIPTION"),
		directlyAccessible: false,
		image: media.metaImages.load,
		title: i18n.t("common:META.REMARKETING_LOAD.TITLE"),
		url: `${getBaseURL(true)}${pages.REMARKETING_LOAD}`
	},

	remarketingPerformance: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.REMARKETING_PERFORMANCE.DESCRIPTION"),
		directlyAccessible: false,
		image: media.metaImages.features,
		title: i18n.t("common:META.REMARKETING_PERFORMANCE.TITLE"),
		url: `${getBaseURL(true)}${pages.REMARKETING_PERFORMANCE}`
	},

	remarketingWebRTC: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.REMARKETING_WEB_RTC.DESCRIPTION"),
		directlyAccessible: false,
		image: media.metaImages.webRTC,
		title: i18n.t("common:META.REMARKETING_WEB_RTC.TITLE"),
		url: `${getBaseURL(true)}${pages.REMARKETING_WEB_RTC}`
	},

	requestOfferForm: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.REQUEST_OFFER_FORM.DESCRIPTION"),
		directlyAccessible: false,
		image: media.metaImages.default,
		title: i18n.t("common:META.REQUEST_OFFER_FORM.TITLE"),
		url: `${getBaseURL(true)}${pages.PERFORMANCE_MONITORING_CONTACT}`
	},

	termsAndConditions: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.TERMS_AND_CONDITIONS.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.default,
		title: i18n.t("common:META.TERMS_AND_CONDITIONS.TITLE"),
		url: `${getBaseURL(true)}${pages.TERMS_AND_CONDITIONS}`
	},

	testCreation: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.TEST_CREATION.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.testCreation,
		title: i18n.t("common:META.TEST_CREATION.TITLE"),
		url: `${getBaseURL(true)}${pages.SERVICES_TEST_CREATION}`
	},

	testResultValidation: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.TEST_RESULT_VALIDATION.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.load,
		title: i18n.t("common:META.TEST_RESULT_VALIDATION.TITLE"),
		url: `${getBaseURL(true)}${pages.TEST_RESULT_VALIDATION}`
	},

	webRTC: {
		alt: i18n.t("common:IMG_ALT.LOADERO"),
		description: i18n.t("common:META.WEB_RTC.DESCRIPTION"),
		directlyAccessible: true,
		image: media.metaImages.webRTC,
		title: i18n.t("common:META.WEB_RTC.TITLE"),
		url: `${getBaseURL(true)}${pages.FEATURES_WEB_RTC}`
	},

	webRTCGuide: {
		alt: i18n.t("common:IMG_ALT.WEB_RTC_GUIDE.GUIDE"),
		description: i18n.t("common:META.WEB_RTC_GUIDE.DESCRIPTION"),
		directlyAccessible: false,
		image: media.metaImages.webRTCGuide,
		title: i18n.t("common:META.WEB_RTC_GUIDE.TITLE"),
		url: `${getBaseURL(true)}${pages.WEB_RTC_GUIDE}`
	}
};

export { og };
