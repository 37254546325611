import { FC } from "react";
import Head from "next/head";

import { pages } from "../../utils/const";
import { navigate } from "../../utils/method";

import media from "../../public/assets/media";

import { OG } from "../../types/types";

interface IProps {
	og?: OG;
}

const HeadMeta: FC<IProps> = ({ og }): JSX.Element => (
	<Head>
		<meta charSet="utf-8" />
		<meta
			name="viewport"
			content="width=device-width, initial-scale=1, shrink-to-fit=yes, viewport-fit=cover, minimum-scale=1.0, maximum-scale=1.0"
		/>
		<meta name="theme-color" content="#502e89" />

		<link rel="icon" type="image/png" sizes="16x16" href="/favicons/16.png" />
		<link rel="icon" type="image/png" sizes="32x32" href="/favicons/32.png" />
		<link rel="icon" type="image/png" sizes="64x64" href="/favicons/64.png" />
		<link rel="icon" type="image/png" sizes="96x96" href="/favicons/96.png" />
		<link
			rel="icon"
			type="image/png"
			sizes="128x128"
			href="/favicons/128.png"
		/>

		{og && <title>{og.title}</title>}

		<meta
			name="description"
			content={
				og?.description ||
				"Run end-to-end performance and load tests for your web service without having to worry about maintaining a test setup!"
			}
		/>
		<meta name="author" content="Loadero" />

		<link rel="manifest" href="/manifest.json" />

		<meta
			property="og:title"
			content={og?.title || "Loadero - Performance and Load Testing Tool"}
		/>
		<meta property="og:type" content="website" />
		<meta property="og:site_name" content="Loadero" />
		<meta property="og:url" content={og?.url || navigate(pages.HOME)} />
		<meta
			property="og:description"
			content={
				og?.description ||
				"Run end-to-end performance and load tests for your web service without having to worry about maintaining a test setup!"
			}
		/>
		<meta
			property="og:image"
			content={(og?.image || media.metaImages.default) as string}
		/>
		<meta property="og:image:alt" content={og?.alt || "Loadero"} />

		<meta name="twitter:card" content="summary" />
		<meta name="twitter:site" content="@Loadero" />
		<meta name="twitter:creator" content="@Loadero" />

		<meta
			name="google-site-verification"
			content="evCN1D2nkyV1B6jODJjgCHAsWWltZxR4YAYKF_KVMMw, V1xDO8iZmuc0clRZOfZAQNNKTCkgwi0LOWQLi_D4uns, GRreoHAo0WUO6Ki7zuAmegsMKVIKQcEqB8Qv2agmLwg"
		/>

		{!og?.directlyAccessible && <meta name="robots" content="noindex" />}
	</Head>
);

export default HeadMeta;
