import { FC, useContext } from "react";
// eslint-disable-next-line
import { i18n as I18n } from "i18next";

import { Divider } from "ui-kit";

import ModalContext from "../../context/modal";

import media from "../../public/assets/media";

import { links, pages } from "../../utils/const";
import { navigate, onEnterKeyDown } from "../../utils/method";

import Icon from "../icon/Icon";
import Image from "../image/Image";

interface IProps {
	i18n: I18n;
}

const Footer: FC<IProps> = ({ i18n }): JSX.Element => {
	const { t } = i18n;

	const { toggleCookieManageModal } = useContext(ModalContext);

	return (
		<div className="footer">
			<Divider orientation="horizontal" theme="light" />

			<div className="footer--top">
				<div className="content footer--top-content">
					<a className="footer__logo" href={pages.HOME}>
						<Image
							src={media.logo.gradient}
							alt={t("common:IMG_ALT.LOADERO")}
						/>
					</a>

					<div className="footer__media">
						<div>{t("FOLLOW_US")}</div>

						<div className="footer__external">
							<a
								aria-label={t("common:IMG_ALT.GITHUB")}
								className="footer__link footer__link--socials"
								href={links.GITHUB}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Icon icon="github" width={28} />
							</a>

							<a
								aria-label={t("common:IMG_ALT.LINKEDIN")}
								className="footer__link footer__link--socials"
								href={links.LINKEDIN}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Icon icon="linkedin" width={28} />
							</a>

							<a
								aria-label={t("common:IMG_ALT.YOUTUBE")}
								className="footer__link footer__link--socials"
								href={links.YOUTUBE}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Icon icon="youtube" width={28} />
							</a>

							<a
								aria-label={t("common:IMG_ALT.FACEBOOK")}
								className="footer__link footer__link--socials"
								href={links.FACEBOOK}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Icon icon="facebook" width={28} />
							</a>
						</div>
					</div>
				</div>
			</div>

			<Divider orientation="horizontal" theme="light" />

			<div className="footer--center">
				<div className="content">
					<div className="footer__info">
						<div className="footer__col">
							<div className="footer__title">{t("FOOTER.USE_CASES")}</div>

							<a className="footer__link" href={pages.FEATURES_WEB_APPS}>
								{t("NAV.WEB_APP")}
							</a>

							<a className="footer__link" href={pages.FEATURES_WEB_RTC}>
								{t("NAV.WEB_RTC")}
							</a>

							<a className="footer__link" href={pages.FEATURES_PERFORMANCE}>
								{t("NAV.PERFORMANCE_TESTING")}
							</a>

							<a className="footer__link" href={pages.FEATURES_LOAD}>
								{t("NAV.LOAD_TESTING")}
							</a>

							<a className="footer__link" href={pages.FEATURES_MONITORING}>
								{t("NAV.SERVICE_MONITORING")}
							</a>
						</div>

						<div className="footer__col">
							<div className="footer__title">{t("FOOTER.INDUSTRIES")}</div>

							<a className="footer__link" href={pages.COMMUNICATIONS}>
								{t("NAV.COMMUNICATIONS")}
							</a>

							<a
								className="footer__link"
								href={pages.COMMERCE}
								rel="noopener noreferrer"
							>
								{t("NAV.COMMERCE")}
							</a>

							<a
								className="footer__link"
								href={pages.E_LEARNING}
								rel="noopener noreferrer"
							>
								{t("NAV.E_LEARNING")}
							</a>

							{/* <a
								className="footer__link"
								href=""
								target="_blank"
								rel="noopener noreferrer"
							>
								{t("NAV.TELEHEATH")}
							</a>

							<a
								className="footer__link"
								href=""
								target="_blank"
								rel="noopener noreferrer"
							>
								{t("NAV.ANTI_DDOS")}
							</a>

							*/}
							<a
								className="footer__link"
								href={pages.EVENT_PLATFORMS}
								rel="noopener noreferrer"
							>
								{t("NAV.EVENT_PLATFORMS")}
							</a>
						</div>

						<div className="footer__col">
							<div className="footer__title">{t("FOOTER.SERVICES")}</div>

							<a className="footer__link" href={pages.SERVICES_LOAD_TESTING}>
								{t("NAV.LOAD_TEST_EXECUTION")}
							</a>

							<a className="footer__link" href={pages.SERVICES_TEST_CREATION}>
								{t("NAV.TEST_CREATION")}
							</a>

							<a
								className="footer__link"
								href={pages.PERFORMANCE_MONITORING}
								rel="noopener noreferrer"
							>
								{t("NAV.PERFORMANCE_MONITORING")}
							</a>

							<a className="footer__link" href={pages.TEST_RESULT_VALIDATION}>
								{t("NAV.TEST_RESULT_VALIDATION")}
							</a>
						</div>

						<div className="footer__col">
							<div className="footer__title">{t("FOOTER.COMPANY")}</div>

							{/* <a className="footer__link" href="">
								{t("NAV.WHY_LOADERO")}
							</a> */}

							<a className="footer__link" href={pages.TERMS_AND_CONDITIONS}>
								{t("NAV.TERMS_AND_CONDITIONS")}
							</a>

							<a className="footer__link" href={pages.PRIVACY_POLICY}>
								{t("NAV.PRIVACY_POLICY")}
							</a>

							<a className="footer__link" href={pages.COOKIE_POLICY}>
								{t("NAV.COOKIE_POLICY")}
							</a>

							<div
								onClick={toggleCookieManageModal}
								className="footer__link"
								tabIndex={0}
								onKeyDown={event =>
									onEnterKeyDown(event, toggleCookieManageModal)
								}
							>
								{t("NAV.COOKIE_PREFERENCES")}
							</div>

							<a className="footer__link" href={pages.PARTNER}>
								{t("NAV.BECOME_PARTNER")}
							</a>
						</div>

						<div className="footer__col">
							<div className="footer__title">{t("FOOTER.RESOURCES")}</div>

							<a className="footer__link" href={pages.PRICING}>
								{t("NAV.PRICING")}
							</a>

							<a className="footer__link" href={links.BLOG}>
								{t("NAV.BLOG")}
							</a>

							<a className="footer__link" href={links.WIKI} target="_blank">
								{t("NAV.DOCS")}
							</a>
						</div>

						<div className="footer__col">
							<div className="footer__title">{t("FOOTER.GET_STARTED")}</div>

							<a className="footer__link" href={navigate(pages.REGISTER)}>
								{t("NAV.REGISTER")}
							</a>

							<a className="footer__link" href={navigate(pages.LOGIN)}>
								{t("NAV.LOG_IN")}
							</a>

							<a
								className="footer__link"
								href={links.PIPEDRIVE.SCHEDULE_MEETING}
							>
								{t("NAV.BOOK_DEMO")}
							</a>

							<Image
								className="footer__iso-badge"
								src={media.isoBadge}
								alt={t("common:IMG_ALT.BADGES.ISO")}
								width={96}
								height={84}
								unoptimized
							/>
						</div>
					</div>
				</div>
			</div>

			<Divider orientation="horizontal" theme="medium" />

			<div className="content">
				<div className="footer__bottom-info">
					<div className="footer__copyright">
						{t("FOOTER.COPY_RIGHT", {
							year: new Date().getFullYear()
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
