import i18n from "../config/i18n";

const isNumeric = (num: string) =>
	!Number.isNaN(parseFloat(num)) && Number.isFinite(num);

const validateEmail = (email: string) => {
	if (!email || email.length === 0) {
		return i18n.t("common:ERROR.REQUIRED");
	}

	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!re.test(email)) {
		return i18n.t("common:ERROR.EMAIL");
	}

	return "";
};

const validateRequired = (name: string) => {
	if (!name || name.length === 0) {
		return i18n.t("common:ERROR.REQUIRED");
	}

	return "";
};

export { isNumeric, validateEmail, validateRequired };
