import { FC, RefObject, useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Trans } from "react-i18next";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import { Button, Switch } from "ui-kit";

import { config, getCrossDomain } from "../../config/config";

import ModalContext from "../../context/modal";

import { Cookies } from "../../types/types";

import { cookies, cookiesDefault, env } from "../../utils/const";
import { updateGTM } from "../../config/ga";

const cookieName =
	config.env === env.PROD
		? cookies.CONSENT
		: `${cookies.CONSENT}_${config.env}`;

interface IProps {
	t: TFunction;
}

const CookiesManageModal: FC<IProps> = ({ t }): JSX.Element => {
	const modalInner: RefObject<HTMLDivElement> = useRef(null);

	const { toggleCookieManageModal } = useContext(ModalContext);

	const [showBg, setShowBg] = useState<boolean>(true);

	const [cookie, setCookie] = useCookies();

	const [cookieData, setCookieData] = useState<Cookies>(
		cookie[cookieName] ? { ...cookie[cookieName] } : { ...cookiesDefault }
	);

	const handleSwitch = (name: string, val: boolean): void => {
		let editedCookies = {};

		switch (name) {
			case "personalization_storage":
				editedCookies = {
					personalization_storage: val,
					functionality_storage: val
				};

				break;

			case "analytics_storage":
				editedCookies = {
					ad_personalization: val,
					ad_storage: val,
					ad_user_data: val,
					analytics_storage: val
				};

				break;
		}

		setCookieData({ ...cookieData, ...editedCookies });
	};

	const handleSubmit = (): void => {
		setCookie(
			cookieName,
			{ ...cookieData },
			{
				path: "/",
				domain: getCrossDomain()
			}
		);

		updateGTM(cookieData);

		toggleCookieManageModal();
	};

	useEffect(() => {
		const modal = modalInner.current;

		const handleScroll = (): void => {
			const modal = modalInner.current;

			if (
				modal &&
				modal.scrollHeight - modal.offsetHeight - modal.scrollTop > 20
			) {
				setShowBg(true);

				return;
			}

			setShowBg(false);
		};

		if (modalInner.current) {
			modal?.addEventListener("scroll", handleScroll);
			window.addEventListener("resize", handleScroll);
		}

		return () => {
			modal?.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleScroll);
		};
	}, []);

	return (
		<div className="c-modal">
			<div className="c-modal__title">{t("common:COOKIES_BANNER.TITLE")}</div>
			<div ref={modalInner} className="c-modal__switchers-wrapper">
				<div className="c-modal__switchers">
					<div className="c-modal__block">
						<div className="c-modal__block-top">
							<div className="c-modal__block-label">
								<Trans i18nKey={"common:COOKIES_BANNER.SWITCH_1.LABEL"} t={t}>
									Necessary <span>(always on)</span>
								</Trans>
							</div>

							<Switch
								name="security_storage"
								disabled
								selected={true}
								onChange={() => {}}
							/>
						</div>

						<div className="c-modal__block-note">
							{t("common:COOKIES_BANNER.SWITCH_1.NOTE")}
						</div>
					</div>

					<div className="c-modal__block">
						<div className="c-modal__block-top">
							<div className="c-modal__block-label">
								{t("common:COOKIES_BANNER.SWITCH_2.LABEL")}
							</div>

							<Switch
								name="personalization_storage"
								selected={cookieData.personalization_storage}
								onChange={handleSwitch}
							/>
						</div>

						<div className="c-modal__block-note">
							{t("common:COOKIES_BANNER.SWITCH_2.NOTE")}
						</div>
					</div>

					<div className="c-modal__block">
						<div className="c-modal__block-top">
							<div className="c-modal__block-label">
								{t("common:COOKIES_BANNER.SWITCH_3.LABEL")}
							</div>

							<Switch
								name="analytics_storage"
								selected={cookieData.analytics_storage}
								onChange={handleSwitch}
							/>
						</div>

						<div className="c-modal__block-note">
							{t("common:COOKIES_BANNER.SWITCH_3.NOTE")}
						</div>
					</div>
				</div>
			</div>

			{showBg && <div className="c-modal__switchers-bg" />}

			<Button className="c-modal__button" size="sm" onClick={handleSubmit}>
				{t("common:COOKIES_BANNER.BUTTON_MANAGE")}
			</Button>
		</div>
	);
};

export default CookiesManageModal;
