import i18n from "i18next";

import common from "../../translations/eng/common.json";

export const resources = {
	"en-US": {
		common
	} as const
};

i18n.init({
	debug: false,
	defaultNS: "common",
	fallbackLng: "en-US",
	lng: "en-US",
	react: {
		bindI18n: "languageChanged loaded",
		nsMode: "default",
		bindI18nStore: false
	},
	ns: ["common"],
	resources
});

export default i18n;
