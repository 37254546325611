import {
	FC,
	Fragment,
	PropsWithChildren,
	ReactNode,
	useEffect,
	useState
} from "react";

interface IProps {
	children: PropsWithChildren<ReactNode>;
}

const NoSSR: FC<IProps> = ({ children }): JSX.Element | null => {
	const [isMounted, setMount] = useState(false);

	useEffect(() => {
		setMount(true);
	}, []);

	return <Fragment>{isMounted ? children : null}</Fragment>;
};

export default NoSSR;
