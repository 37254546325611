import { FC, KeyboardEvent } from "react";

import { SVGIcon } from "ui-kit";

import { iconSet } from "./iconSet";

interface IProps {
	className?: string;
	height?: number;
	icon: keyof typeof iconSet;
	"data-for"?: string;
	"data-tip"?: string;
	onClick?(
		e?: React.MouseEvent<HTMLElement, MouseEvent> | MouseEvent | TouchEvent
	): void;
	onKeyUp?(event: KeyboardEvent): void;
	tabIndex?: number;
	width?: number;
}

const Icon: FC<IProps> = ({
	className = "",
	height,
	icon,
	onClick,
	onKeyUp,
	tabIndex,
	width,
	...otherProps
}): JSX.Element => {
	const newProps = {
		"data-for": otherProps["data-for"] || "",
		"data-tip": otherProps["data-tip"] || "",
		className: className || `${icon}-icon`,
		height,
		onClick,
		onKeyUp,
		tabIndex,
		width
	};

	return (
		<div className="icon">
			<SVGIcon
				customIconSet={iconSet}
				iconName={icon}
				{...newProps}
				className={className}
			/>
		</div>
	);
};

export default Icon;
