import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { useRouter } from "next/router";

import { Button, Divider } from "ui-kit";

import media from "../../public/assets/media";

import { config } from "../../config/config";

import { cookies, env, links, pages } from "../../utils/const";
import { classNames, navigate, onEnterKeyDown } from "../../utils/method";

import Icon from "../icon/Icon";
import Image from "../image/Image";

import i18n from "./i18n";

interface IProps {
	active: boolean;
	toggleMenu: () => void;
}

const MobileMenu: FC<IProps> = ({ active, toggleMenu }): JSX.Element => {
	const { t } = i18n;
	const router = useRouter();

	const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
	const [activeHeaderItem, setActiveHeaderItem] = useState<number | null>(null);

	const [cookie] = useCookies([cookies.LOGGED_IN, cookies.LOGGED_IN_STAGE]);

	const handleCloseMenu = (): void => {
		toggleMenu();
		(
			document.getElementsByClassName("mobile-menu__logo")[0] as HTMLElement
		).focus();
	};

	const handleDropdownClick = (value: number): void => {
		if (activeHeaderItem === value) {
			setActiveHeaderItem(null);

			return;
		}

		setActiveHeaderItem(value);
	};

	useEffect(() => {
		const handleResize = (): void => {
			if (window.innerWidth > 1024) toggleMenu();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [toggleMenu]);

	useEffect(() => {
		if (config.env === env.STAGE) {
			setUserLoggedIn(cookie.loggedInStage === true);
		} else {
			setUserLoggedIn(cookie.loggedIn === true);
		}
	}, [cookie.loggedIn, cookie.loggedInStage]);

	useEffect(() => {
		const handleCloseModal = (): void => {
			toggleMenu();
		};

		router.events.on("routeChangeStart", handleCloseModal);

		return () => {
			return router.events.off("routeChangeStart", handleCloseModal);
		};
	}, [router, toggleMenu]);

	return (
		<div
			hidden={!active}
			className={classNames(["mobile-menu", active && "mobile-menu--active"])}
		>
			<div className="mobile-menu__header">
				<div className="content content--space-between">
					<a className="mobile-menu__logo" href={pages.HOME}>
						<Image src={media.logo.header} alt={t("common:IMG_ALT.LOADERO")} />
					</a>

					<div
						className="mobile-menu__cross"
						tabIndex={0}
						onKeyDown={event => {
							onEnterKeyDown(event, handleCloseMenu);
						}}
						onClick={() => toggleMenu()}
					>
						<Icon icon="close-header" />
					</div>
				</div>
			</div>

			<Divider theme="light" />

			<div className="mobile-menu__actions">
				<Button
					href={navigate(pages.LOGIN)}
					brand={userLoggedIn ? "primary" : "secondary"}
				>
					{userLoggedIn ? t("NAV.GO_TO_APP") : t("NAV.LOG_IN")}
				</Button>

				{!userLoggedIn && (
					<Button href={navigate(pages.REGISTER)} className="button--register">
						{t("NAV.TRY_FOR_FREE")}
					</Button>
				)}
			</div>

			<Divider theme="light" />

			<div className="mobile-menu--bottom">
				{/* <a className="mobile-menu__link" href={pages.WHY_LOADERO}>
					{t("NAV.WHY_LOADERO")}
				</a> */}

				<div
					className={classNames([
						"mobile-menu__dropdown",
						activeHeaderItem === 0 && "mobile-menu__dropdown--active"
					])}
					onClick={() => handleDropdownClick(0)}
					tabIndex={0}
					onKeyDown={event =>
						onEnterKeyDown(event, () => handleDropdownClick(0))
					}
				>
					<div className="mobile-menu__dropdown--top">
						{t("NAV.USE_CASES")}

						<Icon icon="caret_down" width={20} />
					</div>

					<div className="mobile-menu__dropdown-list">
						<div className="mobile-menu__dropdown-item">
							<a href={pages.FEATURES_WEB_APPS}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-webrtc">
									<Image
										src={media.header.useCasesWebAppTesting}
										alt={t("common:IMG_ALT.HEADER.USE_CASES.WEBRTC_TESTING")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.USE_CASES.FEATURES.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.USE_CASES.FEATURES.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.FEATURES_WEB_RTC}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-webrtc">
									<Image
										src={media.header.useCasesWebRTC}
										alt={t("common:IMG_ALT.HEADER.USE_CASES.WEBRTC_TESTING")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.USE_CASES.WEB_RTC_TESTING.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.USE_CASES.WEB_RTC_TESTING.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.FEATURES_PERFORMANCE}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-performance">
									<Image
										src={media.header.useCasesPerformance}
										alt={t(
											"common:IMG_ALT.HEADER.USE_CASES.PERFORMANCE_TESTING"
										)}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.USE_CASES.PERFORMANCE_TESTING.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.USE_CASES.PERFORMANCE_TESTING.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.FEATURES_LOAD}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-load-testing">
									<Image
										src={media.header.useCasesLoad}
										alt={t("common:IMG_ALT.HEADER.USE_CASES.LOAD_TESTING")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.USE_CASES.LOAD_TESTING.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.USE_CASES.LOAD_TESTING.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.FEATURES_MONITORING}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-monitoring">
									<Image
										src={media.header.useCasesUptime}
										alt={t("common:IMG_ALT.HEADER.USE_CASES.UPTIME_MONITORING")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.USE_CASES.UPTIME_MONITORING.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.USE_CASES.UPTIME_MONITORING.CONTENT")}
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div
					className={classNames([
						"mobile-menu__dropdown",
						activeHeaderItem === 1 && "mobile-menu__dropdown--active"
					])}
					onClick={() => handleDropdownClick(1)}
					tabIndex={0}
					onKeyDown={event =>
						onEnterKeyDown(event, () => handleDropdownClick(1))
					}
				>
					<div className="mobile-menu__dropdown--top">
						{t("NAV.INDUSTRIES")}

						<Icon icon="caret_down" width={20} />
					</div>

					<div className="mobile-menu__dropdown-list">
						<div className="mobile-menu__dropdown-item">
							<a href={pages.COMMUNICATIONS}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-communications">
									<Image
										src={media.header.industryCommunications}
										alt={t("common:IMG_ALT.HEADER.INDUSTRIES.COMMUNICATIONS")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.INDUSTRIES.COMMUNICATIONS.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.INDUSTRIES.COMMUNICATIONS.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.COMMERCE}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-commerce">
									<Image
										src={media.header.industryCommerce}
										alt={t("common:IMG_ALT.HEADER.INDUSTRIES.COMMERCE")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.INDUSTRIES.COMMERCE.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.INDUSTRIES.COMMERCE.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						{/* <div className="mobile-menu__dropdown-item">
							<a href="/">
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-telehealth">
									<Image
										src={media.header.industryTelehealth}
										alt={t("common:IMG_ALT.HEADER.INDUSTRIES.TELEHEATH")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.INDUSTRIES.TELEHEATH.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.INDUSTRIES.TELEHEATH.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href="/">
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-anti-ddos">
									<Image
										src={media.header.industryAntiDDos}
										alt={t("common:IMG_ALT.HEADER.INDUSTRIES.ANTI_DDOS")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.INDUSTRIES.ANTI_DDOS.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.INDUSTRIES.ANTI_DDOS.CONTENT")}
									</div>
								</div>
							</a>
						</div> */}

						<div className="mobile-menu__dropdown-item">
							<a href={pages.E_LEARNING}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-elearning">
									<Image
										src={media.header.industryELearning}
										alt={t("common:IMG_ALT.HEADER.INDUSTRIES.E_LEARNING")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.INDUSTRIES.E_LEARNING.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.INDUSTRIES.E_LEARNING.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.EVENT_PLATFORMS}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-event-platforms">
									<Image
										src={media.header.industryEventPlatforms}
										alt={t("common:IMG_ALT.HEADER.INDUSTRIES.EVENT_PLATFORMS")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.INDUSTRIES.EVENT_PLATFORMS.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.INDUSTRIES.EVENT_PLATFORMS.CONTENT")}
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div
					className={classNames([
						// "mobile-menu__link",
						"mobile-menu__dropdown",
						activeHeaderItem === 2 && "mobile-menu__dropdown--active"
					])}
					onClick={() => handleDropdownClick(2)}
					tabIndex={0}
					onKeyDown={event =>
						onEnterKeyDown(event, () => handleDropdownClick(2))
					}
				>
					<div className="mobile-menu__dropdown--top">
						{t("NAV.SERVICES")}

						<Icon icon="caret_down" width={20} />
					</div>

					<div className="mobile-menu__dropdown-list">
						<div className="mobile-menu__dropdown-item">
							<a href={pages.SERVICES_LOAD_TESTING}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-load-execution">
									<Image
										src={media.header.servicesLoadExecution}
										alt={t(
											"common:IMG_ALT.HEADER.SERVICES.LOAD_TEST_EXECUTION"
										)}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.SERVICES.LOAD_TEST_EXECUTION.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.SERVICES.LOAD_TEST_EXECUTION.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.SERVICES_TEST_CREATION}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-test-creation">
									<Image
										src={media.header.servicesTestCreation}
										alt={t("common:IMG_ALT.HEADER.SERVICES.TEST_CREATION")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.SERVICES.TEST_CREATION.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.SERVICES.TEST_CREATION.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.PERFORMANCE_MONITORING}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-performance-monitoring">
									<Image
										src={media.header.servicesPerformanceMonitoring}
										alt={t(
											"common:IMG_ALT.HEADER.SERVICES.PERFORMANCE_MONITORING"
										)}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.SERVICES.PERFORMANCE_MONITORING.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.SERVICES.PERFORMANCE_MONITORING.CONTENT")}
									</div>
								</div>
							</a>
						</div>

						<div className="mobile-menu__dropdown-item">
							<a href={pages.TEST_RESULT_VALIDATION}>
								<div className="mobile-menu__dropdown-item--inner mobile-menu__dropdown-item--inner-run-validation">
									<Image
										src={media.header.servicesResultValidation}
										alt={t("common:IMG_ALT.HEADER.SERVICES.TEST_RUN_VALIDATION")}
										width={20}
										height={20}
									/>

									<div className="mobile-menu__dropdown-item--inner-title">
										{t("HEADER.SERVICES.TEST_RUN_VALIDATION.TITLE")}
									</div>

									<div className="mobile-menu__dropdown-item--inner-content">
										{t("HEADER.SERVICES.TEST_RUN_VALIDATION.CONTENT")}
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>

				<a className="mobile-menu__link" href={pages.PRICING}>
					{t("NAV.PRICING")}
				</a>

				<a
					className="mobile-menu__link mobile-menu__link--book-now"
					target="_blank"
					href={links.PIPEDRIVE.SCHEDULE_MEETING}
				>
					{t("NAV.BOOK_DEMO")}
				</a>

				<a
					className="mobile-menu__link"
					href={links.BLOG}
					rel="noopener noreferrer"
					onClick={() => toggleMenu()}
				>
					{t("NAV.BLOG")}
				</a>

				<a
					className="mobile-menu__link mobile-menu__link--docs"
					href={links.WIKI}
					onClick={() => toggleMenu()}
					target="_blank"
				>
					{t("NAV.DOCS")}
				</a>
			</div>
		</div>
	);
};

export default MobileMenu;
