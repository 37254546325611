const allRegionsToolTip = [
	"AP East - Hong Kong",
	"AP Northeast - Tokyo",
	"AP Northeast - Seoul",
	"AP South - Mumbai",
	"AP Southeast - Sydney",
	"EU Central - Frankfurt",
	"EU West - Ireland",
	"EU West - Paris",
	"SA East - São Paulo",
	"US East - North Virginia",
	"US East - Ohio",
	"US West - Oregon"
];

const blog = {
	ARTICLES_ALL_LIMIT: 9999,
	ARTICLES_MUST_READ_LIMIT: 5,
	TOP_TAGS_LIMIT: 20,
	TOP_TAGS_NO_LIMIT: 9999
};

const cssClasses = {
	ACTIVE: "active",
	ANIMATED_IN: "animated-in",
	NO_OVERSCROLL_BEHAVIOUR: "no-overscroll-behavior",
	NO_SCROLL: "no-scroll",
	NO_TRANSITION: "no-transition",
	POSITION_FIXED: "position-fixed"
};

const cookies = {
	AD_PERSONALIZATION: "ad_personalization",
	AD_STORAGE: "ad_storage",
	AD_USER_DATA: "ad_user_data",
	ANALYTICS_STORAGE: "analytics_storage",
	CONSENT: "consent",
	DENIED: "denied",
	FUNCTIONALITY_STORAGE: "functionality_storage",
	GRANTED: "granted",
	LOGGED_IN: "loggedIn",
	LOGGED_IN_STAGE: "loggedInStage",
	PERSONALIZATION_STORAGE: "personalization_storage",
	SECURITY_STORAGE: "security_storage",
	STORAGE_KEY: "cookies"
};

const cookiesDefault = {
	ad_personalization: false,
	ad_storage: false,
	ad_user_data: false,
	analytics_storage: false,
	functionality_storage: false,
	personalization_storage: false,
	security_storage: true
};

const env = {
	DEV: "dev",
	PROD: "prod",
	STAGE: "stage"
};

const modifierClasses = {
	e_learning: "e-learning",
	load: "load",
	performance: "performance",
	web_rtc: "web-rtc"
};

const keyCodes = {
	ENTER: 13,
	ESC: 27,
	SPACE: 32,
	TAB: 9
};

const limitedRegionsToolTip = [
	"AP South - Mumbai",
	"AP Southeast - Sydney",
	"EU Central - Frankfurt",
	"EU West - Ireland",
	"SA East - São Paulo",
	"US West - Oregon",
	"US East - North Virginia"
];

const links = {
	BLOG: "/blog/",
	CU_BLOG_POST:
		"/blog/articles/introducing-compute-units-how-to-choose-correct-compute-power-for-your-test/",
	FACEBOOK: "https://www.facebook.com/loadero/",
	GITHUB: "https://github.com/loadero",
	INTRO_VIDEO:
		"https://www.youtube-nocookie.com/embed/pk5HPeQMDtg?autoplay=1&rel=0",
	LINKEDIN: "https://www.linkedin.com/company/loadero",
	LOAD_TEST: {
		INTRO_VIDEO: "https://www.youtube.com/embed/pk5HPeQMDtg"
	},
	LOADERO: "https://loadero.com",
	LOGIN: "https://loadero.com/login/",
	META_BASE_URL: "https://cdn.loadero.com/meta",
	PIPEDRIVE: {
		SCHEDULE_MEETING:
			"https://loadero.pipedrive.com/scheduler/rA0lp9Sk/demo-call-with-loadero"
	},
	PRICING: "https://loadero.com/pricing",
	PRIVACY_POLICY: "https://loadero.com/privacy-policy",
	REGISTER: "https://loadero.com/register/",
	TERMS_AND_CONDITIONS: "https://loadero.com/terms-and-conditions",
	WEB_RTC: {
		INTRO_VIDEO: "https://www.youtube.com/embed/12e4aZ9Vo5Y"
	},
	WIKI: "https://wiki.loadero.com",
	WIKI_METRIC_DOCUMENTATION:
		"https://wiki.loadero.com/docs/results/report-summary#machine-statistics",
	WIKI_ASSERT_DOCUMENTATION:
		"https://wiki.loadero.com/docs/test-creation/asserts/asserts",
	YOUTUBE: "https://www.youtube.com/channel/UCX9I2XOh0J_vsIkWfbkaLXw"
};

const pageName = {
	MONITORING_FEATURE: "Monitoring feature"
};

const pages = {
	BLOG: "/blog/",
	BLOG_ARTICLE: "/blog/articles/",
	BLOG_CATEGORIES: "/blog/categories/",
	BLOG_TAGS: "/blog/tags/",
	COMMERCE: "/industry/commerce/",
	COMMUNICATIONS: "/industry/communications/",
	COOKIE_POLICY: "/cookie-policy/",
	E_LEARNING: "/industry/e-learning/",
	EVENT_PLATFORMS: "/industry/event-platforms/",
	FEATURES: "/features/",
	FEATURES_LOAD: "/features/load/",
	FEATURES_MONITORING: "/features/monitoring/",
	FEATURES_PERFORMANCE: "/features/performance/",
	FEATURES_WEB_APPS: "/features/web-applications/",
	FEATURES_WEB_RTC: "/features/webrtc/",
	FORGOT_PASSWORD: "/forgot-password/",
	HOME: "/",
	LOGIN: "/login/",
	PAGE_NOT_FOUND: "/page-not-found/",
	PARTNER: "/partner/",
	PERFORMANCE_MONITORING: "/services/performance-monitoring/",
	PERFORMANCE_MONITORING_CONTACT: "/services/performance-monitoring/contact/",
	PRICING: "/pricing/",
	PRIVACY_POLICY: "/privacy-policy/",
	REGISTER: "/register/",
	REMARKETING_LOAD: "/features/load/step-by-step/",
	REMARKETING_PERFORMANCE: "/features/performance/step-by-step/",
	REMARKETING_WEB_RTC: "/features/webrtc/step-by-step/",
	SERVICES_TEST_CREATION: "/services/test-creation/",
	SERVICES_LOAD_TESTING: "/services/load-testing/",
	TERMS_AND_CONDITIONS: "/terms-and-conditions/",
	TEST_RESULT_VALIDATION: "/services/result-validation/",
	UPGRADE: "/upgrade/",
	WEB_RTC_GUIDE: "/webrtc-guide/",
	WHY_LOADERO: "/why-loadero/"
};

const pricingPlans = {
	ENTERPRISE: "enterprise",
	ESSENTIAL: {
		name: "essential",
		value: "single_basic"
	},
	PERIODS: {
		MONTHLY: "monthly",
		YEARLY: "yearly"
	},
	SUBSCRIPTION: "subscription",
	ULTIMATE: {
		name: "ultimate",
		value: "single_pro"
	}
} as const;

const routes = {
	ARTICLE_BASIC: "/blog/articles/",
	BLOG_SUBSCRIBE: "/proxy/blog/",
	WEB_RTC_GUIDE: "/proxy/webrtc_guide/"
};

const screenSizes = {
	MOBILE: 767,
	TABLET_LANDSCAPE: 1024
};

const supportEmail = "support@loadero.com";

const tags = {
	MUST_READ: "#must-read",
	UNCATEGORIZED: "#uncategorized"
};

const testCaseOptions = {
	CASES_10: "10 test cases",
	CASES_20: "20 test cases",
	CASES_30: "30 test cases",
	CUSTOM: "custom"
};

const toastAlert = {
	AUTO_CLOSE_TIME: 6000
};

const translationFiles = {
	BLOG: "blog",
	ARTICLE: "article",
	COMMERCE: "commerce",
	COMMON: "common",
	COMMUNICATIONS: "communications",
	COOKIE_POLICY: "cookiePolicy",
	E_LEARNING: "eLearning",
	EVENT_PLATFORMS: "eventPlatforms",
	FEATURES: "features",
	HOME: "home",
	LOAD: "load",
	LOAD_TEST_EXECUTION: "loadTestExecution",
	MONITORING: "monitoring",
	NOT_FOUND: "notFound",
	PARTNER: "partner",
	PERFORMANCE: "performance",
	PERFORMANCE_MONITORING: "performanceMonitoring",
	PRICING: "pricing",
	PRIVACY_POLICY: "privacyPolicy",
	REMARKETING_WEB_RTC: "remarketingWebRTC",
	REMARKETING_LOAD: "remarketingLoad",
	REMARKETING_PERFORMANCE: "remarketingPerformance",
	RESULT_VALIDATION: "resultValidation",
	SERVICES: "services",
	SUBSCRIBE_BANNER: "subscribeBanner",
	TERMS_AND_CONDITIONS: "termsAndConditions",
	TEST_CREATION: "testCreation",
	WEB_APP: "webApplications",
	WEB_RTC: "webRTC",
	WEB_RTC_GUIDE: "webRTCGuide"
} as const;

export {
	allRegionsToolTip,
	blog,
	cssClasses,
	cookies,
	cookiesDefault,
	env,
	modifierClasses,
	keyCodes,
	limitedRegionsToolTip,
	links,
	pageName,
	pages,
	pricingPlans,
	routes,
	screenSizes,
	supportEmail,
	tags,
	testCaseOptions,
	toastAlert,
	translationFiles
};
