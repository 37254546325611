import { FC, KeyboardEvent, useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import { Button, Input } from "ui-kit";

import { discordAPI } from "../../api";

import { config } from "../../config/config";

import ModalContext from "../../context/modal";

import { env, keyCodes } from "../../utils/const";
import {
	camelCaseToWords,
	classNames,
	createPipedriveRecord,
	objectValues
} from "../../utils/method";
import { validateEmail, validateRequired } from "../../utils/validation";

type FormState = {
	name: string;
	email: string;
	messageBody: string;
};

interface IProps {
	customLabel?: string;
	onClose?: () => void;
	onSubmit: () => void;
	page?: string;
	subtitle: string;
	title: string;
	t: TFunction;
}

const ContactForm: FC<IProps> = ({
	onClose,
	onSubmit,
	customLabel = "",
	page = "",
	subtitle,
	title,
	t
}): JSX.Element => {
	const { sourcePath, setSourcePath } = useContext(ModalContext);

	const parentDivRef = useRef(null);

	const [messageInfo, setMessageInfo] = useState<FormState>({
		name: "",
		email: "",
		messageBody: ""
	});

	const [formError, setFormError] = useState<FormState>({
		name: "",
		email: "",
		messageBody: ""
	});
	const { name, email, messageBody } = messageInfo;

	const [fetching, setFetching] = useState<boolean>(false);

	const validateForm = (): boolean => {
		const fError = {} as FormState;

		fError.name = validateRequired(name);
		fError.email = validateEmail(email);
		fError.messageBody = validateRequired(messageBody);

		setFormError(fError);

		const isValid = objectValues(fError).every(err => err === "");

		return isValid;
	};

	const onChange = (fieldName: string, value: string, message = ""): void => {
		setMessageInfo({
			...messageInfo,
			[fieldName]: value
		});

		setFormError({
			...formError,
			[fieldName]: message
		});
	};

	const handleSubmit = (): void => {
		if (fetching || !validateForm()) return;

		if (
			email.split("@")[1] === "loadero.qa" ||
			(config.env !== env.PROD && config.env !== env.STAGE)
		) {
			onSubmit();

			return;
		}

		setFetching(true);

		const message =
			`Landing - ${camelCaseToWords(sourcePath)} page\n\n` +
			`Name: ${name}\n` +
			`Email: ${email}\n` +
			`Message: ${messageBody}`;

		const discordData = { message, email, name };
		const pipedriveData = { message, email, name };

		setSourcePath("");

		onSubmit();

		Promise.all([
			createPipedriveRecord(pipedriveData),
			discordAPI.sendMessage(discordData)
		]).then(
			() => {
				setMessageInfo({
					name: "",
					email: "",
					messageBody: ""
				});

				setFetching(false);
			},
			() => {
				toast.error(t("common:ERROR.DEFAULT"));

				setFetching(false);
			}
		);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
		const cancelBtn = document.querySelector(".contact-form__cancel");
		const messageInput = document.querySelector("#messageBody");

		if (
			e.keyCode === keyCodes.ENTER &&
			((cancelBtn && cancelBtn === document.activeElement) ||
				(messageInput && messageInput === document.activeElement))
		) {
			return;
		}

		if (e.keyCode === keyCodes.ENTER) {
			handleSubmit();
		}
	};

	const getLabel = (): JSX.Element => {
		const charsLeft = 2000 - messageBody?.length;

		return (
			<span className="contact-form__message">
				{`${
					customLabel || t("common:CONTACT_FORM.MESSAGE")
				}. (${t("common:CONTACT_FORM.CHARACTER_LIMIT")}: `}
				<span
					className={classNames([
						charsLeft <= 100 && "contact-form__character-count--active"
					])}
				>
					{charsLeft}
				</span>
				)
			</span>
		);
	};

	return (
		<section
			className={classNames(["contact-form", page && "contact-form--page"])}
			ref={parentDivRef}
			onKeyDown={handleKeyDown}
			tabIndex={0}
		>
			<div className={classNames([page && "content"])}>
				<div className={classNames([!page && "modal__container"])}>
					<div className="contact-form__title">{title}</div>

					<div className="contact-form__subtitle">{subtitle}</div>
				</div>

				{!page && <div className="modal__divider" />}

				<div className="contact-form-body">
					<div className={classNames([!page && "modal__container"])}>
						<div className="contact-form__user-details">
							<Input
								onBlur={onChange}
								onChange={onChange}
								error={formError.name}
								inputName="name"
								inputValue={name}
								label={t("common:CONTACT_FORM.NAME")}
								required
							/>

							<Input
								onBlur={onChange}
								onChange={onChange}
								error={formError.email}
								inputName="email"
								inputValue={email}
								label={t("common:CONTACT_FORM.EMAIL")}
								required
							/>
						</div>

						<Input
							onBlur={onChange}
							onChange={onChange}
							error={formError.messageBody}
							inputName="messageBody"
							inputValue={messageBody}
							label={getLabel()}
							maxLength={2000}
							rows={7}
							noBottomMargin
							multiline
							required
						/>
					</div>

					{!page && <div className="modal__divider" />}

					<div className={classNames([!page && "modal__container"])}>
						<div className="contact-form__button-wrapper">
							{!page && (
								<Button
									brand="secondary"
									size="sm"
									className="contact-form__cancel"
									onClick={onClose}
								>
									{t("common:CANCEL")}
								</Button>
							)}

							<Button
								size={page ? "md" : "sm"}
								className="contact-form__send-msg"
								onClick={handleSubmit}
								loading={fetching}
							>
								{t("common:CONTACT_FORM.SEND_MESSAGE")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
