import axios from "axios";

import { pipedrive } from "../config/config";
import {
	DealData,
	DealsResponse,
	NoteData,
	NotesResponse,
	PersonData,
	PersonsResponse,
	SourceFieldData
} from "../types/pipedrive";

const { baseUrl, token } = pipedrive;

const pipedriveRest = axios.create({
	baseURL: baseUrl + "/v1",
	headers: { "content-type": "application/json" }
});

pipedriveRest.interceptors.response.use(response => response.data);

const createDeal = (data: DealData): Promise<DealsResponse> => {
	const url = `/deals?api_token=${token}`;

	return pipedriveRest.post(url, data);
};

const createNote = (data: NoteData): Promise<NotesResponse> => {
	const url = `/notes?api_token=${token}`;

	return pipedriveRest.post(url, data);
};

const createPerson = (data: PersonData): Promise<PersonsResponse> => {
	const url = `/persons?api_token=${token}`;

	return pipedriveRest.post(url, data);
};

const updateDealField = (
	dealID: number,
	data: SourceFieldData
): Promise<void> => {
	const url = `/deals/${dealID}?api_token=${token}`;

	return pipedriveRest.put(url, data);
};

const pipedriveAPI = {
	createDeal,
	createNote,
	createPerson,
	updateDealField
};

export default pipedriveAPI;
