import { links } from "../../utils/const";

import isoBadge from "./media/badges/iso-badge.png";
import defaultBlogImage from "./media/blogPage/defaultImage.png";
import greyArrow from "./media/greyArrow.png";
import logoGradient from "./media/logoGradient.svg";
import logoHeader from "./media/logo-header.svg";
import letter from "./media/partner/letter.svg";
import webRTCArrowDown from "./media/landingPages/webrtc/downLight.svg";

import caretDownIcon from "./media/caret-down-icon.svg";
import industryAntiDDos from "./media/header/industry-icons-anti-ddos.svg";
import industryCommerce from "./media/header/industry-icons-commerce.svg";
import industryCommunications from "./media/header/industry-icons-communications.svg";
import industryELearning from "./media/header/industry-icons-e-learning.svg";
import industryEventPlatforms from "./media/header/industry-icons-event-platforms.svg";
import industryTelehealth from "./media/header/industry-icons-telehealth.svg";
import servicesLoadExecution from "./media/header/services-load-execution.svg";
import servicesPerformanceMonitoring from "./media/header/services-performance-monitoring.svg";
import servicesResultValidation from "./media/header/services-test-result-validation.svg";
import servicesTestCreation from "./media/header/services-test-creation.svg";
import useCasesLoad from "./media/header/use-cases-load.svg";
import useCasesPerformance from "./media/header/use-cases-performance.svg";
import useCasesUptime from "./media/header/use-cases-uptime.svg";
import useCasesWebAppTesting from "./media/header/use-cases-web-app-testing.svg";
import useCasesWebRTC from "./media/header/use-cases-web-rtc.svg";

const media = {
	isoBadge,
	defaultBlogImage,
	greyArrow,
	header: {
		caretDown: caretDownIcon,
		industryAntiDDos,
		industryCommerce,
		industryCommunications,
		industryELearning,
		industryEventPlatforms,
		industryTelehealth,
		servicesLoadExecution,
		servicesPerformanceMonitoring,
		servicesResultValidation,
		servicesTestCreation,
		useCasesLoad,
		useCasesPerformance,
		useCasesUptime,
		useCasesWebAppTesting,
		useCasesWebRTC
	},
	arrowDown: webRTCArrowDown,
	logo: {
		gradient: logoGradient,
		header: logoHeader
	},
	metaImages: {
		default: `${links.META_BASE_URL}/loaderoDefaultMeta.png`,
		eLearning: `${links.META_BASE_URL}/eLearningMeta.png`,
		features: `${links.META_BASE_URL}/featureMetaImg.png`,
		load: `${links.META_BASE_URL}/landingLoadTestMeta.png`,
		testCreation: `${links.META_BASE_URL}/testCreationMetaImg.png`,
		webRTC: `${links.META_BASE_URL}/webRtcMetaImg.png`,
		webRTCGuide: `${links.META_BASE_URL}/webrtcGuideMeta.png`
	},
	letter
};

export default media;
