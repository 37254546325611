import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { usePathname } from "next/navigation";
// eslint-disable-next-line
import { i18n as I18n } from "i18next";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Button, Divider } from "ui-kit";

import { config } from "../../config/config";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import media from "../../public/assets/media";

import { cookies, env, links, pages } from "../../utils/const";
import {
	arrayIncludes,
	classNames,
	navigate,
	onEnterKeyDown
} from "../../utils/method";

import Icon from "../icon/Icon";
import Image from "../image/Image";

interface IProps {
	defaultNavPos: number;
	mobileMenuActive: boolean;
	noGradientNavbar?: boolean;
	page: string;
	showMobileMenu: (active: boolean) => void;
	i18n: I18n;
}

const Header: FC<IProps> = ({
	defaultNavPos,
	mobileMenuActive,
	noGradientNavbar,
	page,
	showMobileMenu,
	i18n
}): JSX.Element => {
	const { t } = i18n;

	const [transformOut, setTransformOut] = useState<boolean>(false);
	const [defaultBg, setDefaultBg] = useState<boolean>(false);
	const [isScrolled, setIsScrolled] = useState<boolean>(false);
	const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
	const [activeHeaderItem, setActiveHeaderItem] = useState<number | null>(null);
	const [cookie] = useCookies([cookies.LOGGED_IN, cookies.LOGGED_IN_STAGE]);

	const navRef = useRef(null);
	const pathname = usePathname();

	useScrollPosition(
		({ prevPos, currPos }) => {
			if (activeHeaderItem !== null) {
				setActiveHeaderItem(null);
			}

			if (prevPos.y >= 0) return;

			const isHidden = currPos.y < prevPos.y && currPos.y < -60;
			if (isHidden !== transformOut) setTransformOut(isHidden);

			if (defaultNavPos > 0) {
				const isDefault = defaultNavPos <= currPos.y * -1;
				if (isDefault !== defaultBg) setDefaultBg(isDefault);
			}

			setIsScrolled(currPos.y < -100);
		},
		[isScrolled, transformOut]
	);

	const openMobileMenu = (): void => {
		showMobileMenu(true);
	};

	const navbarClasses = (): string => {
		let className = "navigation-bar";

		if (transformOut) {
			className += " navigation-bar--transformed-out";
		}

		if (mobileMenuActive) {
			className += " navigation-bar--menu-active";
		}

		const isDark = [
			pages.HOME,
			pages.PRICING,
			pages.FEATURES_WEB_RTC,
			pages.WEB_RTC_GUIDE,
			pages.FEATURES_LOAD,
			pages.FEATURES_PERFORMANCE,
			pages.FEATURES_MONITORING
		].some(route => {
			if (page === "/") return true;

			return route !== "/" && arrayIncludes(page || "", route.substring(1));
		});

		const isBlog = [
			pages.BLOG,
			pages.BLOG_ARTICLE,
			pages.BLOG_CATEGORIES,
			pages.BLOG_TAGS
		].some(route => route === page || pathname?.includes(pages.BLOG));

		if (isDark) {
			className += " navigation-bar--dark";
		}

		if (noGradientNavbar) {
			className += " navigation-bar--no-gradient";
		}

		if (defaultBg) {
			className += " navigation-bar--default-bg";
		}

		if (isBlog) {
			className += " navigation-bar--blog-page";
		}

		return className;
	};

	const handleDropdownClick = (value: number): void => {
		setActiveHeaderItem(value);
	};

	useOnClickOutside(navRef, () => {
		setActiveHeaderItem(null);
	});

	useEffect(() => {
		const { scrollTop } = document.documentElement;

		setDefaultBg(false);

		if (config.env === env.STAGE) {
			setUserLoggedIn(cookie.loggedInStage === true);
		} else {
			setUserLoggedIn(cookie.loggedIn === true);
		}

		return () => {
			setDefaultBg(defaultNavPos <= scrollTop);
		};
	}, [cookie.loggedIn, cookie.loggedInStage, defaultNavPos]);

	return (
		<div ref={navRef} className={navbarClasses()}>
			<div className="navigation-bar__gradient-fader">
				<div className="content content__navigation-bar">
					<div className="content__navigation-bar--left">
						<a className="navigation-bar__logo" href={pages.HOME}>
							<Image
								src={media.logo.header}
								alt={t("common:IMG_ALT.LOADERO")}
							/>
						</a>

						<div className="navigation-bar__nav">
							<Divider orientation="vertical" theme="light" />

							{/* <a className="navigation-bar__link" href={pages.WHY_LOADERO}>
								{t("NAV.WHY_LOADERO")}
							</a> */}

							<div
								className={classNames([
									"navigation-bar__dropdown",
									activeHeaderItem === 0 && `navigation-bar__dropdown--active`
								])}
								tabIndex={0}
								onClick={() => handleDropdownClick(0)}
								onKeyDown={event =>
									onEnterKeyDown(event, () => handleDropdownClick(0))
								}
							>
								{t("NAV.USE_CASES")}

								<Icon icon="caret_down" width={12} />

								<div className="navigation-bar__dropdown-list navigation-bar__dropdown-list--columns">
									<div className="navigation-bar__dropdown-item">
										<a href={pages.FEATURES_WEB_APPS}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-webrtc">
												<Image
													src={media.header.useCasesWebAppTesting}
													alt={t(
														"common:IMG_ALT.HEADER.USE_CASES.WEBRTC_TESTING"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.USE_CASES.FEATURES.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.USE_CASES.FEATURES.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.FEATURES_WEB_RTC}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-webrtc">
												<Image
													src={media.header.useCasesWebRTC}
													alt={t(
														"common:IMG_ALT.HEADER.USE_CASES.WEBRTC_TESTING"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.USE_CASES.WEB_RTC_TESTING.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.USE_CASES.WEB_RTC_TESTING.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.FEATURES_PERFORMANCE}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-performance">
												<Image
													src={media.header.useCasesPerformance}
													alt={t(
														"IMG_ALT.HEADER.USE_CASES.PERFORMANCE_TESTING"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.USE_CASES.PERFORMANCE_TESTING.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.USE_CASES.PERFORMANCE_TESTING.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.FEATURES_LOAD}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-load-testing">
												<Image
													src={media.header.useCasesLoad}
													alt={t(
														"common:IMG_ALT.HEADER.USE_CASES.LOAD_TESTING"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.USE_CASES.LOAD_TESTING.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.USE_CASES.LOAD_TESTING.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.FEATURES_MONITORING}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-monitoring">
												<Image
													src={media.header.useCasesUptime}
													alt={t(
														"common:IMG_ALT.HEADER.USE_CASES.UPTIME_MONITORING"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.USE_CASES.UPTIME_MONITORING.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.USE_CASES.UPTIME_MONITORING.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item" />
								</div>
							</div>

							<div
								className={classNames([
									"navigation-bar__dropdown",
									activeHeaderItem === 1 && `navigation-bar__dropdown--active`
								])}
								onClick={() => handleDropdownClick(1)}
								tabIndex={0}
								onKeyDown={event =>
									onEnterKeyDown(event, () => handleDropdownClick(1))
								}
							>
								{t("NAV.INDUSTRIES")}

								<Icon icon="caret_down" width={12} />

								<div className="navigation-bar__dropdown-list navigation-bar__dropdown-list--columns">
									<div className="navigation-bar__dropdown-item">
										<a href={pages.COMMUNICATIONS}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-communications">
												<Image
													src={media.header.industryCommunications}
													alt={t(
														"common:IMG_ALT.HEADER.INDUSTRIES.COMMUNICATIONS"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.INDUSTRIES.COMMUNICATIONS.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.INDUSTRIES.COMMUNICATIONS.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.COMMERCE}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-commerce">
												<Image
													src={media.header.industryCommerce}
													alt={t("common:IMG_ALT.HEADER.INDUSTRIES.COMMERCE")}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.INDUSTRIES.COMMERCE.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.INDUSTRIES.COMMERCE.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									{/* <div className="navigation-bar__dropdown-item">
										<a href="/">
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-telehealth">
												<Image
													src={media.header.industryTelehealth}
													alt={t("common:IMG_ALT.HEADER.INDUSTRIES.TELEHEATH")}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.INDUSTRIES.TELEHEATH.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.INDUSTRIES.TELEHEATH.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href="/">
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-anti-ddos">
												<Image
													src={media.header.industryAntiDDos}
													alt={t("common:IMG_ALT.HEADER.INDUSTRIES.ANTI_DDOS")}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.INDUSTRIES.ANTI_DDOS.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.INDUSTRIES.ANTI_DDOS.CONTENT")}
												</div>
											</div>
										</a>
									</div> */}

									<div className="navigation-bar__dropdown-item">
										<a href={pages.E_LEARNING}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-elearning">
												<Image
													src={media.header.industryELearning}
													alt={t("common:IMG_ALT.HEADER.INDUSTRIES.E_LEARNING")}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.INDUSTRIES.E_LEARNING.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.INDUSTRIES.E_LEARNING.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.EVENT_PLATFORMS}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-event-platforms">
												<Image
													src={media.header.industryEventPlatforms}
													alt={t(
														"common:IMG_ALT.HEADER.INDUSTRIES.EVENT_PLATFORMS"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.INDUSTRIES.EVENT_PLATFORMS.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.INDUSTRIES.EVENT_PLATFORMS.CONTENT")}
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>

							<div
								className={classNames([
									"navigation-bar__dropdown",
									activeHeaderItem === 2 && `navigation-bar__dropdown--active`
								])}
								onClick={() => handleDropdownClick(2)}
								tabIndex={0}
								onKeyDown={event =>
									onEnterKeyDown(event, () => handleDropdownClick(2))
								}
							>
								{t("NAV.SERVICES")}

								<Icon icon="caret_down" width={12} />

								<div className="navigation-bar__dropdown-list navigation-bar__dropdown-list--columns">
									<div className="navigation-bar__dropdown-item">
										<a href={pages.SERVICES_LOAD_TESTING}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-load-execution">
												<div className="navigation-bar__dropdown-item--inner-title">
													<Image
														src={media.header.servicesLoadExecution}
														alt={t(
															"IMG_ALT.HEADER.SERVICES.LOAD_TEST_EXECUTION"
														)}
														width={20}
														height={20}
													/>

													{t("HEADER.SERVICES.LOAD_TEST_EXECUTION.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.SERVICES.LOAD_TEST_EXECUTION.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.SERVICES_TEST_CREATION}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-test-creation">
												<Image
													src={media.header.servicesTestCreation}
													alt={t(
														"common:IMG_ALT.HEADER.SERVICES.TEST_CREATION"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.SERVICES.TEST_CREATION.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.SERVICES.TEST_CREATION.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.PERFORMANCE_MONITORING}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-performance-monitoring">
												<Image
													src={media.header.servicesPerformanceMonitoring}
													alt={t(
														"IMG_ALT.HEADER.SERVICES.PERFORMANCE_MONITORING"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.SERVICES.PERFORMANCE_MONITORING.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.SERVICES.PERFORMANCE_MONITORING.CONTENT")}
												</div>
											</div>
										</a>
									</div>

									<div className="navigation-bar__dropdown-item">
										<a href={pages.TEST_RESULT_VALIDATION}>
											<div className="navigation-bar__dropdown-item--inner navigation-bar__dropdown-item--inner-run-validation">
												<Image
													src={media.header.servicesResultValidation}
													alt={t(
														"common:IMG_ALT.HEADER.SERVICES.TEST_RUN_VALIDATION"
													)}
													width={20}
													height={20}
												/>

												<div className="navigation-bar__dropdown-item--inner-title">
													{t("HEADER.SERVICES.TEST_RUN_VALIDATION.TITLE")}
												</div>

												<div className="navigation-bar__dropdown-item--inner-content">
													{t("HEADER.SERVICES.TEST_RUN_VALIDATION.CONTENT")}
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>

							<a
								className={`navigation-bar__link ${
									page === pages.PRICING ? "navigation-bar__link--active" : ""
								}`}
								href={pages.PRICING}
							>
								{t("NAV.PRICING")}
							</a>

							<a
								className="navigation-bar__link navigation-bar__link--book-now"
								target="_blank"
								href={links.PIPEDRIVE.SCHEDULE_MEETING}
							>
								{t("NAV.BOOK_DEMO")}
							</a>
						</div>
					</div>

					<div className="navigation-bar__actions">
						<a
							className="navigation-bar__actions-link"
							href={links.BLOG}
							rel="noopener noreferrer"
						>
							{t("NAV.BLOG")}
						</a>

						<a
							className="navigation-bar__actions-link navigation-bar__actions-link--docs"
							href={links.WIKI}
							target="_blank"
						>
							{t("NAV.DOCS")}
						</a>

						<Divider orientation="vertical" theme="light" />

						{userLoggedIn ? (
							<Button href={navigate(pages.LOGIN)} size="sm">
								{t("NAV.GO_TO_APP")}
							</Button>
						) : (
							<Fragment>
								<a
									className="navigation-bar__actions-link"
									href={navigate(pages.LOGIN)}
								>
									{t("NAV.LOG_IN")}
								</a>

								<Button
									href={navigate(pages.REGISTER)}
									className="button--register"
									size="sm"
								>
									{t("NAV.TRY_FOR_FREE")}
								</Button>
							</Fragment>
						)}
					</div>

					<div className="navigation-bar__mobile-menu">
						{!userLoggedIn && (
							<Button
								href={navigate(pages.REGISTER)}
								className="button--register"
								size="sm"
							>
								{t("NAV.TRY_FOR_FREE")}
							</Button>
						)}

						<div
							className="navigation-bar__mobile-menu-button"
							onClick={openMobileMenu}
							tabIndex={0}
							onKeyDown={event => {
								onEnterKeyDown(event, openMobileMenu);
							}}
						>
							{t("NAV.MENU")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
