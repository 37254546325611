import { FC, useState } from "react";

import ModalContext from "./index";

const ModalProvider: FC<{ children: JSX.Element }> = ({
	children
}): JSX.Element => {
	const [contactUsModalActive, setContactUsModalActive] =
		useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<string>("");
	const [sourcePath, setSourcePath] = useState<string>("");
	const [successModalActive, setSuccessModalActive] = useState<boolean>(false);
	const [cookieManageActive, setCookieManageActive] = useState<boolean>(false);

	const toggleContactUsModal = (): void => {
		setContactUsModalActive(!contactUsModalActive);
	};

	const toggleSuccessModal = (currPage = ""): void => {
		setSuccessModalActive(!successModalActive);
		setCurrentPage(currPage);
	};

	const toggleCookieManageModal = (): void => {
		setCookieManageActive(!cookieManageActive);
	};

	return (
		<ModalContext.Provider
			value={{
				contactUsModalActive,
				cookieManageActive,
				successModalActive,
				currentPage,
				setSourcePath,
				sourcePath,
				toggleContactUsModal,
				toggleCookieManageModal,
				toggleSuccessModal
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export default ModalProvider;
