import axios from "axios";

import { config } from "../config/config";

const proxyRest = axios.create({
	baseURL: config.proxy.url,
	timeout: 15000,
	headers: {
		Authorization: `Basic ${config.proxy.basicToken}`
	}
});

export { proxyRest };
