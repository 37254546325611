import { RefObject, useEffect } from "react";

import { keyCodes } from "../utils/const";

const useOnClickOutside = (
	ref: RefObject<HTMLDivElement>,
	handler: (event: Event | KeyboardEvent) => void
): void => {
	useEffect(() => {
		const listener = (event: Event): void => {
			if (!ref.current || ref.current.contains(event.target as Node)) {
				return;
			}

			handler(event);
		};

		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.keyCode === keyCodes.ESC) handler(event);
		};

		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		document.addEventListener("keydown", handleKeyDown);

		return (): void => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [ref, handler]);
};

export default useOnClickOutside;
