import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";

import { Button } from "ui-kit";

import HeadMeta from "../../components/headMeta/HeadMeta";
import Image from "../../components/image/Image";

import { pages } from "../../utils/const";
import { og } from "../../utils/metaTags";
import { navigate } from "../../utils/method";

import i18n from "./i18n";
import { media } from "./media";

require("./PageNotFound.module.scss");

const PageNotFound = (): JSX.Element => {
	const { t } = i18n;

	const router = useRouter();

	const [isBlog, setIsBlog] = useState<boolean>(false);

	const { notFound } = og;

	useEffect(() => {
		setIsBlog(router.asPath.startsWith(pages.BLOG));
	}, [router.asPath]);

	const renderActions = (): JSX.Element => {
		if (isBlog) {
			return (
				<Button href={pages.BLOG} className="button--not-found-primary">
					{t("notFound:BLOG_PAGE")}
				</Button>
			);
		}

		return (
			<Fragment>
				<Button href={pages.HOME} className="button--not-found-primary">
					{t("notFound:HOME_PAGE")}
				</Button>

				<Button
					href={navigate(pages.LOGIN)}
					className="button--not-found-secondary"
				>
					{t("notFound:LOG_IN")}
				</Button>
			</Fragment>
		);
	};

	const renderMessage = (): JSX.Element => (
		<Fragment>
			<div className="page-not-found__message">{t("notFound:MESSAGE")}</div>

			<div className="page-not-found__sub-message">
				{t("notFound:SUB_MESSAGE")}
			</div>
		</Fragment>
	);

	return (
		<Fragment>
			<HeadMeta og={notFound} />

			<div className="page-not-found">
				<a href={pages.HOME} className="page-not-found__logo-link">
					<Image
						src={media.logoGradient}
						alt={t("common:IMG_ALT.LOADERO")}
						className="page-not-found__logo"
					/>
				</a>

				<div className="page-not-found__content-block">
					<div className="page-not-found__left-col">
						<div className="page-not-found__status-code">404</div>

						{renderMessage()}

						<div className="page-not-found__redirect">{renderActions()}</div>
					</div>

					<div className="page-not-found__right-col">
						<Image
							src={media.pageNotFound}
							alt={t("common:IMG_ALT.PAGE_NOT_FOUND")}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export const getStaticProps = () => ({
	props: {
		name: pages.PAGE_NOT_FOUND
	}
});

export default PageNotFound;
