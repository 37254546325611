import { KeyboardEvent } from "react";

import { getBaseURL } from "../config/config";
import { ArticlePost, Tag } from "../types/blog/blog";

import { keyCodes, pages, tags } from "./const";
import { pipedriveAPI } from "../api";
import { RecordData } from "../types/pipedrive";

const arrayIncludes = (arr: string[] | string, value: string): boolean => {
	if (arr.includes) {
		return arr.includes(value);
	}

	return arr.indexOf(value) > -1;
};

const camelCaseToKebab = (str: string): string =>
	str.replace(
		/[A-Z]+(?![a-z])|[A-Z]/g,
		($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
	);

const camelCaseToWords = (s: string): string => {
	const result = s.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
};

const classNames = (classes: unknown[]): string =>
	classes.filter(c => !!c).join(" ");

const createPipedriveRecord = async (data: RecordData): Promise<unknown> => {
	const { email, name, message } = data;

	// Create person
	const personData = { email: email, name };
	const person = await pipedriveAPI.createPerson(personData);

	// Create deal
	const dealData = { title: email, person_id: person.data.id };
	const deal = await pipedriveAPI.createDeal(dealData);

	// Create note
	const noteData = {
		content: message,
		deal_id: deal.data.id
	};

	return pipedriveAPI.createNote(noteData);
};

const getTagNameBySlugFromArticleList = (
	articles: ArticlePost[],
	slug: string
): string => {
	return articles[0].tags.find(tag => tag.slug === slug)?.name || "";
};

const getPrivateArticlesBySlug = (
	articles: ArticlePost[],
	slug: string
): ArticlePost[] => {
	return articles.filter(({ tags }) => tags.some(el => el.slug === slug));
};

const getArticlePrivateTags = (article: ArticlePost): string[] => {
	return article.tags
		.filter(tag => tag.name.startsWith("#") && tag.name !== tags.MUST_READ)
		.map(innerTag => innerTag.name);
};

const getPrivateTagsFromTags = (tagsArray: Tag[]): Tag[] => {
	return tagsArray.filter(
		tag => tag.name.startsWith("#") && tag.name !== tags.MUST_READ
	);
};

const getPublicTagsFromTags = (tagsArray: Tag[]): Tag[] => {
	return tagsArray.filter(tag => !tag.name.startsWith("#"));
};

const getSlugFromName = (name: string): string => {
	return name.replace(/#/g, "");
};

const handleOpenContactUsModal = (
	toggleFn: () => void,
	setSourcePath: (path: string) => void,
	page: string,
	event?: KeyboardEvent<HTMLAnchorElement>
): void => {
	setSourcePath(page);

	if (event) {
		onEnterKeyDown(event, toggleFn);
		return;
	}

	toggleFn();
};

const navigate = (url: string, qp?: string): string => {
	const baseURL = getBaseURL();

	const { BLOG, HOME, LOGIN, REGISTER, UPGRADE } = pages;

	switch (url) {
		case BLOG:
			return `${baseURL}${BLOG}`;
		case LOGIN:
			return `${baseURL}${LOGIN}`;
		case REGISTER:
			return `${baseURL}${REGISTER}${qp || ""}`;
		case UPGRADE:
			return `${baseURL}${UPGRADE}${qp || ""}`;
		default:
			return `${baseURL}${HOME}`;
	}
};

const noPropagationLinkClick = (event: MouseEvent, url: string): void => {
	event.preventDefault();
	window.open(url, "_blank");
};

const objectEntries = (obj: { [key: string]: unknown }): unknown[][] => {
	if (Object.entries) {
		return Object.entries(obj);
	}

	const ownProps = Object.keys(obj);
	let i = ownProps.length;
	const resArray = new Array(i);

	while (i > 0) {
		i -= 1;

		resArray[i] = [ownProps[i], obj[ownProps[i]]];
	}

	return resArray;
};

const objectValues = (obj: { [key: string]: string }) => {
	if (Object.values) {
		return Object.values(obj);
	}

	return Object.keys(obj).map(elem => obj[elem]);
};

const onEnterKeyDown = (e: KeyboardEvent, cb: () => void): void => {
	if (e.keyCode !== keyCodes.ENTER) return;

	e.stopPropagation();

	cb();
};

const reformatDate = (value: string): string => {
	const date = new Date(value);
	return date.toLocaleDateString("en-US", { dateStyle: "long" });
};

const snakeToCamel = (str: string): string =>
	str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace("_", ""));

const sortArticlesByDate = (arr: ArticlePost[]) => {
	return arr.sort((a, b) => {
		return Date.parse(b.created_at) - Date.parse(a.created_at);
	});
};

const sortArticlesByTagTitle = (
	arr: ArticlePost[],
	tagName: string
): ArticlePost[] =>
	arr.filter(({ tags }) => tags.some(el => el.name === tagName));

const sortTagsByCount = (arr: Tag[]): Tag[] =>
	arr.sort((a, b) => b.count.posts - a.count.posts);

export {
	arrayIncludes,
	camelCaseToKebab,
	camelCaseToWords,
	classNames,
	createPipedriveRecord,
	getArticlePrivateTags,
	getPrivateArticlesBySlug,
	getPrivateTagsFromTags,
	getPublicTagsFromTags,
	getSlugFromName,
	getTagNameBySlugFromArticleList,
	handleOpenContactUsModal,
	navigate,
	noPropagationLinkClick,
	objectEntries,
	objectValues,
	onEnterKeyDown,
	reformatDate,
	snakeToCamel,
	sortArticlesByDate,
	sortArticlesByTagTitle,
	sortTagsByCount
};
