import { routes } from "../utils/const";

import { proxyRest } from "./config";

type MailerLiteData = {
	email: string;
};

const addSubscriber = (data: MailerLiteData) => {
	return proxyRest.post(routes.WEB_RTC_GUIDE, data);
};

const addBlogSubscriber = (data: MailerLiteData) => {
	return proxyRest.post(routes.BLOG_SUBSCRIBE, data);
};

export const mailerliteAPI = {
	addSubscriber,
	addBlogSubscriber
};
