import { FC, useContext, useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import FocusTrap from "focus-trap-react";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";

import { SVGIcon } from "ui-kit";

import ModalContext from "../../context/modal";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import media from "../../public/assets/media";

import { cssClasses, pages } from "../../utils/const";
import { onEnterKeyDown } from "../../utils/method";

import Image from "../image/Image";

interface IProps {
	t: TFunction;
	currentPage?: string;
}

const SuccessModal: FC<IProps> = ({ currentPage, t }): JSX.Element => {
	const { toggleSuccessModal, toggleContactUsModal, contactUsModalActive } =
		useContext(ModalContext);

	const modalRef = useRef(null);

	const closeModal = (): void => {
		toggleSuccessModal("");
	};

	const renderModalText = (): JSX.Element => {
		let text = (
			<Trans t={t} i18nKey={"common:CONTACT_FORM.SUCCESS.TEXT_2"}>
				We received your submission
				<br />
				and will contact you soon.
			</Trans>
		);

		switch (currentPage) {
			case pages.WEB_RTC_GUIDE:
				text = (
					<Trans t={t} i18nKey={"common:CONTACT_FORM.SUCCESS.TEXT_3"}>
						The requested e-book has been <br /> sent to the e-mail you
						provided.
					</Trans>
				);
				break;
			case pages.BLOG:
				text = (
					<Trans t={t} i18nKey={"common:CONTACT_FORM.SUCCESS.TEXT_4"}>
						You have been successfully <br /> subscribed to our newsletter.
					</Trans>
				);
				break;
		}

		return text;
	};

	useOnClickOutside(modalRef, () => {
		toggleSuccessModal("");

		if (contactUsModalActive) {
			toggleContactUsModal();
		}
	});

	useEffect(() => {
		const { NO_OVERSCROLL_BEHAVIOUR, NO_SCROLL } = cssClasses;

		document.body.classList.add(NO_SCROLL, NO_OVERSCROLL_BEHAVIOUR);

		return () => {
			document.body.classList.remove(NO_SCROLL, NO_OVERSCROLL_BEHAVIOUR);
		};
	}, []);

	return (
		<div className="success-modal">
			<FocusTrap>
				<div className="success-modal__box" ref={modalRef}>
					<div onKeyDown={event => onEnterKeyDown(event, closeModal)}>
						<SVGIcon
							className="success-modal__close"
							iconName="close"
							onClick={closeModal}
							tabIndex={0}
						/>
					</div>

					<Image
						className="success-modal__icon"
						src={media.letter}
						alt={t("common:IMG_ALT.LETTER")}
					/>

					<div className="success-modal__heading">
						{t("CONTACT_FORM.SUCCESS.TEXT_1")}
					</div>

					<div className="success-modal__subtitle">{renderModalText()}</div>
				</div>
			</FocusTrap>
		</div>
	);
};

export default SuccessModal;
