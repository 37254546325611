import { config, keys } from "../config/config";
import { Cookies } from "../types/types";
import { cookies as cookiesConst, cookiesDefault, env } from "../utils/const";

const {
	AD_PERSONALIZATION,
	AD_STORAGE,
	AD_USER_DATA,
	ANALYTICS_STORAGE,
	FUNCTIONALITY_STORAGE,
	DENIED,
	GRANTED,
	PERSONALIZATION_STORAGE,
	SECURITY_STORAGE
} = cookiesConst;

const injectGTagManagerNoScript = (): void => {
	const bodyEl = document.body;
	const noscriptEl = document.createElement("noscript");
	const iframeEl = document.createElement("iframe");

	iframeEl.src = `https://www.googletagmanager.com/ns.html?id=${keys.GOOGLE_TAG_MANAGER}`;
	iframeEl.height = "0";
	iframeEl.width = "0";
	iframeEl.style.display = "none";
	iframeEl.style.visibility = "hidden";

	noscriptEl.appendChild(iframeEl);

	bodyEl.insertBefore(noscriptEl, bodyEl.firstChild);
};

const injectGTagManagerScript = (): void => {
	const firstScript = document.getElementsByTagName("script")[0];
	const script = document.createElement("script");

	script.src = `https://www.googletagmanager.com/gtm.js?id=${keys.GOOGLE_TAG_MANAGER}`;
	script.async = true;

	firstScript?.parentNode?.insertBefore(script, firstScript);
};

declare global {
	interface Window {
		dataLayer: unknown[];
	}
}

// This function is taken from Google tag documentation:
// https://developers.google.com/tag-platform/gtagjs/install
// So, it is used exactly as mentioned there. But I still needed to
// provide arguments to make typescript complain less
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(_consent: string, _consentArg: string, _cookies: {}) {
	window.dataLayer.push(arguments);
}

const getCookie = (cookieName: string): Cookies => {
	const cookiesList = document.cookie.split(";");

	for (let i = 0; i < cookiesList.length; i++) {
		const cookie = cookiesList[i].trim();

		if (cookie.startsWith(cookieName + "=")) {
			const cookieValue = decodeURIComponent(
				cookie.substring(cookieName.length + 1)
			);

			try {
				return JSON.parse(cookieValue);
			} catch {
				return cookiesDefault;
			}
		}
	}

	return cookiesDefault;
};

const getCookiePermission = (cookie: Cookies, prop: string): string =>
	cookie[prop as keyof Cookies] ? GRANTED : DENIED;

const initGTM = (): void => {
	if (
		typeof window === "undefined" ||
		typeof keys.GOOGLE_TAG_MANAGER === "undefined"
	) {
		return;
	}

	const cookieName =
		config.env !== env.PROD ? `consent_${config.env}` : "consent";

	const cookie = getCookie(cookieName);

	window.dataLayer = [];

	window.dataLayer.push({
		"gtm.start": new Date().getTime(),
		event: "gtm.js"
	});

	gtag("consent", "default", {
		[AD_PERSONALIZATION]: getCookiePermission(cookie, AD_PERSONALIZATION),
		[AD_STORAGE]: getCookiePermission(cookie, AD_STORAGE),
		[AD_USER_DATA]: getCookiePermission(cookie, AD_USER_DATA),
		[ANALYTICS_STORAGE]: getCookiePermission(cookie, ANALYTICS_STORAGE),
		[FUNCTIONALITY_STORAGE]: getCookiePermission(cookie, FUNCTIONALITY_STORAGE),
		[PERSONALIZATION_STORAGE]: getCookiePermission(
			cookie,
			PERSONALIZATION_STORAGE
		),
		[SECURITY_STORAGE]: getCookiePermission(cookie, SECURITY_STORAGE)
	});

	injectGTagManagerScript();
	injectGTagManagerNoScript();
};

const updateGTM = (updatedCookies: Cookies): void => {
	if (
		typeof window === "undefined" ||
		typeof keys.GOOGLE_TAG_MANAGER === "undefined"
	) {
		return;
	}

	const cookies: { [key: string]: string } = {};

	Object.entries(updatedCookies).forEach(([k, v]) => {
		cookies[k] = v ? GRANTED : DENIED;
	});

	window.dataLayer = window.dataLayer || [];

	gtag("consent", "update", cookies);
};

export { initGTM, updateGTM };
