export const iconSet = {
	"alert-close": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 12 12"
		>
			<path
				fill="#FFFFFF"
				fillOpacity=".8"
				fillRule="evenodd"
				d="M7.87 6l3.743 3.742a1.323 1.323 0 0 1-1.871 1.87L6 7.872l-3.742 3.742a1.323 1.323 0 0 1-1.87-1.871L4.128 6 .387 2.258A1.323 1.323 0 0 1 2.258.388L6 4.128 9.742.387a1.323 1.323 0 0 1 1.87 1.871L7.872 6z"
			/>
		</svg>
	),
	"arrow-down": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#682EC7"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M2 5l6 6 6-6"
			/>
		</svg>
	),

	"arrow-right": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#682EC7"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M2.5 8h11m0 0l-5-4.5m5 4.5l-5 4.5"
			/>
		</svg>
	),
	book: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="m9 6.38-1.106-.67A4.765 4.765 0 0 0 3 5.68v8.449a8.086 8.086 0 0 1 5.956.04l.044.018V6.38zm1 10.386-1.787-.722a6.11 6.11 0 0 0-5.981.8C1.724 17.226 1 16.861 1 16.22V5.061c0-.27.126-.525.34-.687a6.75 6.75 0 0 1 7.583-.395L10 4.5l1.077-.521a6.75 6.75 0 0 1 7.583.395c.214.162.34.416.34.687v11.16c0 .64-.724 1.006-1.232.622a6.11 6.11 0 0 0-5.981-.799L10 16.766zm1-2.578V6.38l1.106-.67A4.765 4.765 0 0 1 17 5.68v8.449a8.086 8.086 0 0 0-5.956.04l-.044.018z"
				fill="#262630"
			/>
		</svg>
	),
	caret_down: (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.157 4.2H2.842c-.417 0-.601.555-.275.83l3.157 2.668c.161.136.39.136.552 0L9.433 5.03c.326-.275.142-.83-.276-.83z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	check: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="#20DE92"
				strokeLinecap="round"
				strokeWidth="4"
				d="M3 12l7.5 6L21 6"
			/>
		</svg>
	),
	close: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
		>
			<path
				fill="#B8B8C7"
				fillRule="evenodd"
				d="M11.806 9l5.613 5.613a1.984 1.984 0 0 1-2.806 2.806L9 11.806 3.387 17.42a1.984 1.984 0 0 1-2.806-2.806L6.194 9 .58 3.387A1.984 1.984 0 0 1 3.387.581L9 6.194 14.613.58a1.984 1.984 0 0 1 2.806 2.806L11.806 9z"
			/>
		</svg>
	),
	"close-header": (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.293 3.293a1 1 0 0 1 1.414 0L10 8.586l5.293-5.293a1 1 0 1 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 0 1 0-1.414z"
				fill="#262630"
			/>
		</svg>
	),

	facebook: (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="14" cy="14" r="11.667" fill="#4F4F64" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.1 9.892a6.884 6.884 0 0 0-1.163-.127c-.836 0-.89.364-.89.946v1.035h2.09l-.182 2.146h-1.909v6.525H12.43V13.89h-1.345v-2.145h1.345V10.42c0-1.817.854-2.836 2.999-2.836.745 0 1.29.11 2 .255L17.1 9.892z"
				fill="#fff"
			/>
		</svg>
	),

	github: (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="14" cy="14" r="11.667" fill="#4F4F64" />
			<path
				d="M3.975 19.97a11.7 11.7 0 0 0 6.337 5.1c.584.107.796-.253.796-.562l-.005-.657c-.003-.351-.008-.805-.01-1.327-3.246.705-3.93-1.564-3.93-1.564-.531-1.347-1.296-1.706-1.296-1.706-1.06-.724.08-.71.08-.71 1.171.083 1.787 1.203 1.787 1.203 1.04 1.782 2.731 1.267 3.396.97.106-.755.406-1.27.74-1.56-2.59-.295-5.314-1.296-5.314-5.767 0-1.273.455-2.315 1.201-3.13-.12-.295-.52-1.481.114-3.088 0 0 .98-.314 3.209 1.196.93-.258 1.928-.388 2.92-.392.99.004 1.99.134 2.921.392 2.227-1.51 3.205-1.196 3.205-1.196.637 1.607.236 2.793.116 3.088.748.815 1.2 1.857 1.2 3.13 0 4.482-2.728 5.468-5.328 5.757.42.36.793 1.072.793 2.16 0 1.044-.007 1.952-.011 2.553l-.004.648c0 .312.21.675.803.561a11.644 11.644 0 0 1-3.695.597 11.66 11.66 0 0 1-10.025-5.695z"
				fill="#fff"
			/>
		</svg>
	),

	help: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<circle cx="8" cy="8" r="6" fill="#72708F" />
			<path
				stroke="#fff"
				strokeWidth="1.5"
				d="M6 7c0-1.183.895-2 2-2s2 .817 2 2c0 1.429-2 2-2 2v1M8 11v1"
			/>
		</svg>
	),

	info: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 12 12"
		>
			<path
				fill="#4F4F64"
				fillRule="evenodd"
				d="M6 0a6.001 6.001 0 1 0 .001 12.002A6.001 6.001 0 0 0 6 0zm-.082 9.947a.687.687 0 1 1 0-1.373.687.687 0 0 1 0 1.373zm.75-3.334s-.067.024-.067.064v.545a.684.684 0 0 1-1.367 0v-.545c.006-.62.407-1.168.997-1.36a.97.97 0 0 0 .663-.952.997.997 0 0 0-.982-.945.968.968 0 0 0-.87.551.684.684 0 1 1-1.231-.593A2.326 2.326 0 0 1 5.9 2.053h.013a2.36 2.36 0 0 1 2.344 2.271A2.33 2.33 0 0 1 6.67 6.611l-.001.002z"
			/>
		</svg>
	),

	linkedin: (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="14" cy="14" r="11.667" fill="#4F4F64" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.952 8.332c0 .727-.553 1.314-1.418 1.314-.831 0-1.384-.587-1.367-1.314C8.15 7.571 8.703 7 9.55 7c.849 0 1.385.57 1.402 1.332zM8.236 19.144v-8.46h2.63v8.46h-2.63zM12.973 13.384c0-1.055-.035-1.955-.07-2.699h2.284l.122 1.16h.051c.346-.537 1.211-1.35 2.613-1.35 1.73 0 3.027 1.143 3.027 3.633v5.017h-2.63v-4.688c0-1.09-.38-1.834-1.331-1.834-.727 0-1.16.502-1.332.986-.07.173-.104.415-.104.658v4.878h-2.63v-5.76z"
				fill="#fff"
			/>
		</svg>
	),

	"yellow-check": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm5.76-11.35a1 1 0 0 0-1.52-1.3l-5.39 6.288L5.6 10.2a1 1 0 1 0-1.2 1.6l4.75 3.562 6.61-7.711z"
				fill="#AE8709"
			/>
		</svg>
	),

	youtube: (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="14" cy="14" r="11.667" fill="#4F4F64" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.592 9.554c.233.233.4.522.486.84.495 1.993.381 5.142.01 7.212a1.885 1.885 0 0 1-1.327 1.327c-1.163.317-5.846.317-5.846.317s-4.683 0-5.846-.317a1.885 1.885 0 0 1-1.327-1.327c-.498-1.985-.362-5.135-.01-7.202A1.885 1.885 0 0 1 8.06 9.077c1.164-.317 5.846-.327 5.846-.327s4.683 0 5.846.317c.318.086.608.254.84.487zM16.299 14l-3.884 2.25v-4.5L16.299 14z"
				fill="#fff"
			/>
		</svg>
	)
};
