import {
	cloneElement,
	FC,
	ReactElement,
	ReactNode,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from "react";
import { ToastContainer } from "react-toastify";

// eslint-disable-next-line
import { i18n as I18n } from "i18next";
import { classNames } from "ui-kit/src/utils/methods";

import ContactUsModal from "../../components/contactUsModal/ContactUsModal";
import CookiesBanner from "../../components/cookies/CookiesBanner";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Icon from "../../components/icon/Icon";
import MobileMenu from "../../components/mobileMenu/MobileMenu";
import NoSSR from "../../components/noSSR/NoSSR";
import SuccessModal from "../../components/successModal/SuccessModal";

import ModalContext from "../../context/modal";

import { cssClasses, toastAlert } from "../../utils/const";

import CookiesManageModal from "../cookies/CookiesManageModal";

type Props = {
	noGradientNavbar: boolean;
	name: string;
};

interface IProps {
	children: ReactElement;
	pageProps: Props;
	i18n: I18n;
}
const AppLayout: FC<IProps> = ({ children, pageProps, i18n }): JSX.Element => {
	const { t } = i18n;
	const contentRef: RefObject<HTMLDivElement> = useRef(null);
	const {
		cookieManageActive,
		contactUsModalActive,
		successModalActive,
		currentPage
	} = useContext(ModalContext);

	const [mobileMenu, setMobileMenu] = useState<boolean>(false);
	const [scrollPos, setScrollPos] = useState<number>(0);

	const setDefaultNavPos = (elemHeight: number): void => {
		setScrollPos(elemHeight);
	};

	const toggleMobileMenu = (active = false): void => {
		setMobileMenu(active);
	};

	type CloseButtonProps = {
		closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	};

	const renderCloseButton = ({ closeToast }: CloseButtonProps): ReactNode => (
		<Icon onClick={closeToast} className="alert-close" icon="alert-close" />
	);

	useEffect(() => {
		const chatOpenBtn = document.querySelector("[data-id='chat_opened']");
		const chatCloseBtn = document.querySelector(
			"#crisp-chatbox > div > div > div > div"
		)?.children[2];

		const setScrolling = () => {
			const chatEl = document.querySelector(
				"#crisp-chatbox > div > div"
			) as HTMLElement;
			let chatIsOpen = false;

			if (chatEl && chatEl.hasAttribute("data-visible")) {
				chatIsOpen = chatEl.getAttribute("data-visible") === "true";
			}

			const layout = document.querySelector(".app-layout") as HTMLElement;

			if (layout) {
				if (innerWidth <= 360 && innerHeight <= 780 && chatIsOpen) {
					layout.style.overflowY = "hidden";
				}

				if (!chatIsOpen) layout.style.overflowY = "scroll";
			}
		};

		chatOpenBtn?.parentElement?.addEventListener("click", setScrolling);
		chatCloseBtn?.addEventListener("click", setScrolling);

		return () => {
			chatOpenBtn?.parentElement?.removeEventListener("click", setScrolling);
			chatCloseBtn?.removeEventListener("click", setScrolling);
		};
	}, []);

	useEffect(() => {
		const animationStart = () => {
			if (mobileMenu) {
				contentRef?.current?.classList.add(cssClasses.ANIMATED_IN);
			}
		};

		const animationEnd = () => {
			contentRef?.current?.classList.remove(cssClasses.ANIMATED_IN);
		};

		window.addEventListener("transitionstart", animationStart);
		window.addEventListener("transitionend", animationEnd);

		return () => {
			window.removeEventListener("transitionstart", animationStart);
			window.removeEventListener("transitionend", animationEnd);
		};
	}, [mobileMenu]);

	return (
		<div
			className={classNames([
				"app-layout",
				mobileMenu && "app-layout--mobile-menu-active"
			])}
		>
			<div hidden={mobileMenu} className="app-layout__content-wrapper">
				<ToastContainer
					className="alert-container"
					toastClassName="alert-box"
					progressClassName="alert-progress-bar"
					autoClose={toastAlert.AUTO_CLOSE_TIME}
					closeButton={renderCloseButton}
					newestOnTop
				/>

				<Header
					defaultNavPos={scrollPos}
					noGradientNavbar={pageProps.noGradientNavbar}
					mobileMenuActive={mobileMenu}
					page={pageProps.name}
					showMobileMenu={toggleMobileMenu}
					i18n={i18n}
				/>

				<div
					className={`landing-content${mobileMenu ? " pushed-out" : ""}`}
					ref={contentRef}
				>
					<div className="landing-wrapper">
						{cloneElement(children, {
							setDefaultNavPos
						})}
					</div>
				</div>

				<Footer i18n={i18n} />

				<NoSSR>
					<CookiesBanner t={t} />
				</NoSSR>

				{cookieManageActive && (
					<div className="c-wrapper">
						<CookiesManageModal t={t} />
					</div>
				)}
			</div>

			<MobileMenu active={mobileMenu} toggleMenu={toggleMobileMenu} />

			{contactUsModalActive && <ContactUsModal t={t} />}

			{successModalActive && <SuccessModal currentPage={currentPage} t={t} />}
		</div>
	);
};

export default AppLayout;
