import { useEffect } from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { CookiesProvider } from "react-cookie";

import { ErrorBoundary } from "@sentry/nextjs";

import { initCrisp } from "../config/crisp";
import { initGTM } from "../config/ga";

import { pages } from "../utils/const";

import useKeyBoardFocus from "../hooks/useKeyboardFocus";

import BlogProvider from "../context/blog/Provider";
import ModalProvider from "../context/modal/Provider";

import AppLayout from "../components/appLayout/AppLayout";

import PageNotFound from "./404/index.page";

import "ui-kit/index.scss";
import "../styles/index.scss";
import i18n from "../config/i18n";

type PageProps = {
	noGradientNavbar: boolean;
	name: string;
};

interface IProps {
	pageProps: PageProps;
}

const MyApp = ({ Component, pageProps }: AppProps & IProps): JSX.Element => {
	const router = useRouter();
	useKeyBoardFocus();

	useEffect(() => {
		const handleRouteChange = (): void => {
			if (typeof window !== "undefined") {
				window.history.scrollRestoration = "manual";
			}
		};

		router.events.on("routeChangeStart", handleRouteChange);

		return () => {
			router.events.off("routeChangeStart", handleRouteChange);
		};
	}, [router.events]);

	useEffect(() => {
		initCrisp();
		initGTM();
	}, []);

	const renderLayout = (): JSX.Element => {
		if (pageProps.name === pages.PAGE_NOT_FOUND)
			return <Component {...pageProps} />;

		return (
			<AppLayout pageProps={pageProps} i18n={i18n}>
				<Component {...pageProps} />
			</AppLayout>
		);
	};

	return (
		<ErrorBoundary fallback={<PageNotFound />}>
			<CookiesProvider>
				<BlogProvider>
					<ModalProvider>{renderLayout()}</ModalProvider>
				</BlogProvider>
			</CookiesProvider>
		</ErrorBoundary>
	);
};

export default MyApp;
