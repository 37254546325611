/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";

const ModalContext = createContext({
	contactUsModalActive: false,
	cookieManageActive: false,
	successModalActive: false,
	currentPage: "",
	setSourcePath: (path: string) => {},
	sourcePath: "",
	toggleContactUsModal: () => {},
	toggleCookieManageModal: () => {},
	toggleSuccessModal: (currentPage?: string) => {}
});

export default ModalContext;
